import React from "react"
import { useState } from "react"
import { Certificate } from "../../models"

type State = {
	certificate?: Certificate
	setCertificate: (_: Certificate) => void
}

export const VerifyContext = React.createContext<State>({
	certificate: undefined,
	setCertificate: () => {},
})

export default function VerifyProvider(props: React.PropsWithChildren<{}>) {
	const [certificate, setCertificate] = useState<Certificate>()

	return (<div className="Verify">
		<VerifyContext.Provider value={{
			certificate, setCertificate
		}}>
			{props.children}
		</VerifyContext.Provider>
	</div>)
}