import React, { useContext, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { FacilityUser, User } from "../models"
import LoginFlowHOC from "./Login"

export default function LoginPage() {
	const history = useHistory()

	const onLogin = (user: User) => {
		console.log('ON LOGIN', user)
		if (user.type === "patient") {
			history.push('/')
		} else if (user.type === "facility_user") {
			history.push('/facility')
		}
	}

	return (<div className='Slides'>
		<LoginFlowHOC 
			onLogin={onLogin} 
			allowProviders 
			startMode="login"
		/>
	</div>)
}
