import React, { useContext, useState } from "react"
import { ReactComponent as IconSuccess } from "../../assets/icons/Success.svg"
import { ReactComponent as IconInvalid } from "../../assets/icons/Invalid.svg"
import { ReactComponent as IconFailure } from "../../assets/icons/Failure.svg"
import { ReactComponent as IconDownload } from "../../assets/icons/Download.svg"
import Input from "../../components/Input"
import { CovidTestResult } from "../../models"
import { VerifyContext } from "./context"
import { useHistory } from "react-router-dom"
import { capitalizeFirstLetter } from "../../util"
import moment from "moment"
import Processing from "./Processing"
import ThemedButton from "../../components/Button"


export default function Results() {
	const ctx = useContext(VerifyContext)
	const history = useHistory()

	if (ctx.certificate === undefined) {
		return <span />
	}

	if (ctx.certificate.testResult === null) {
		return <Processing />
	}

	let title: string
	let Icon: React.FC

	switch (ctx.certificate.testResult!) {
		case CovidTestResult.negative:
			title = "Negative"
			Icon = IconSuccess
			break
		case CovidTestResult.inconclusive:
			title = "Inconclusive"
			Icon = IconInvalid
			break
		case CovidTestResult.positive:
			title = "Positive"
			Icon = IconFailure
			break
		default: return <span />;
	}

	const downloadCertificate = () => {
		if (!ctx.certificate) return
		window.open(ctx.certificate.url)
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>
					{capitalizeFirstLetter(CovidTestResult[ctx.certificate.testResult])} Result
				</h1>
				{<Icon />}
			</div>
			<div className="Card-message">
				The person named below had a{ctx.certificate.testResult === CovidTestResult.inconclusive && 'n'} {CovidTestResult[ctx.certificate.testResult]} COVID-19 antigen test result{ctx.certificate.testResult !== CovidTestResult.negative && <> and should <b>not</b> travel{ctx.certificate.testResult === CovidTestResult.inconclusive && ' until they receive a negative PCR test'}</>}.
			</div>
			<div style={{ padding: 32, paddingTop: 0 }}>
				<Input 
					label="Traveler name" 
					value={ctx.certificate.name}
					InputProps={{
						readOnly: true
					}}
				/>
				<Input 
					label="Date of birth" 
					value={moment(ctx.certificate.dateOfBirth).format('Do MMM yyyy')}
					InputProps={{
						readOnly: true
					}}
				/>
				<Input 
					label="Test result at" 
					value={moment(ctx.certificate.testResultAt).format('Do MMM yyyy [at] HH:mm a')}
					InputProps={{
						readOnly: true
					}}
				/>
				{ctx.certificate.testResult === CovidTestResult.negative && <ThemedButton style={{ marginTop: 32 }} onClick={downloadCertificate}>
					<IconDownload style={{ marginRight: 12, height: 20, width: 20 }} fill="white" />
					Download Certificate
				</ThemedButton>}
			</div>
		</div>
	)
}
