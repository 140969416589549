import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, Autocomplete, Box } from "@mui/material"
import { ReactComponent as IconHomeAddress } from "../../assets/icons/HomeAddress.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { flagEmoji } from "../../util"
import { CovidTestsContext } from "./context"
import { INavigate } from "."
import countries from "../../countries"
import { AppContext } from "../../context"


export default function Address(props: INavigate) {
	const ctx = useContext(CovidTestsContext)

	const [parishValue, setParishValue] = useState<string>()
	const [didNext, setDidNext] = useState<boolean>(false)

	const PARISHES = [
		"Devonshire",
		"Hamilton",
		"Paget",
		"Pembroke",
		"Sandys",
		"Southampton",
		"Smith's",
		"St. Georges",
		"Warwick"
	]

	const invalidParish: boolean = ctx.country === "BM" && ctx.parish !== undefined && !PARISHES.includes(ctx.parish)

	const onNext = () => {
		setDidNext(true)

		if (!ctx.homeAddress || invalidParish || !ctx.postcode || !ctx.country) {
			return
		} else {
			props.goNext()
		}
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Home Address</h1>
				<IconHomeAddress />
			</div>
			<Grid container direction="column" className="Card-form" sx={{ px: 4, pt: 0, pb: 3 }}>
				<Grid item>
					<Input 
						label="Street address" 
						autoComplete="street-address"
						value={ctx.homeAddress}
						onChange={e => ctx.setHomeAddress(e.target.value)}
						error={!ctx.homeAddress && didNext}
					/>
				</Grid>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs>
						<Autocomplete 
							key="parish-input"
							clearOnEscape={false}
							clearOnBlur={false}
							options={ctx.country === "BM" ? PARISHES : []}
							value={ctx.parish}
							onInputChange={(e, value) => ctx.setParish(value)}
							renderInput={params => 
								<Input {...params} 
									label={ctx.country === "BM" ? "Parish" : "State"}
									autoComplete="address-level2"
									error={invalidParish && didNext} 
									InputProps={ctx.country === "BM" ? params.InputProps : undefined}
								/>
							}
						/>
					</Grid>
					<Grid item xs>
						<Input 
							label="Postcode" 
							value={ctx.postcode}
							onChange={e => {
								let value = e.target.value.toUpperCase()
								if (ctx.country === "BM") {
									value = value.substring(0, 4)
								}
								ctx.setPostcode(value)
							}}
							autoComplete="postal-code"
							error={!ctx.postcode && didNext}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<Autocomplete 
						options={countries}
						clearOnEscape={false}
						clearOnBlur={false}
						value={countries.find(c => c.code === ctx.country)}
						onChange={(e, value) => ctx.setCountry(value?.code ?? undefined)}
						getOptionLabel={option => `${flagEmoji(option.code)}  ${option.label}`}
						renderInput={params => 
							<Input {...params} 
								label="Country" 
								autoComplete="country" 
								error={!ctx.country && didNext}
							/>
						}
					/>
				</Grid>
				<Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={onNext}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
