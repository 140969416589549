import React from "react"
import { alpha, styled } from "@mui/material/styles"
import { TextField, TextFieldProps } from "@mui/material"

const Input = (props: TextFieldProps) => (
	<TextField
		variant="standard"
		{...props}
		sx={{
			width: '100%',
			marginTop: 1.5,
			'& .MuiInput-underline:before': {
				borderBottomColor: 'var(--color-border) !important',
			},
			'&:hover .MuiInput-underline:before': {
				borderBottomColor: 'var(--color-border) !important',
			},
			...props.sx
		}}
		FormHelperTextProps={{
			sx: { color: 'var(--color-text3)' },
			...props.FormHelperTextProps
		}}
		InputLabelProps={{
			sx: {
				'&:focus': {
					color: 'red'
				},
				color: 'var(--color-text3)'
			},
			...props.InputLabelProps
		}}
		InputProps={{
			sx: { color: 'var(--color-text)' },
			...props.InputProps
		}}
	/>
)

export default Input