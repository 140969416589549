import React from "react"
import './Filters.css'

export type Filter = {
	key: any
	icon: JSX.Element
	title: string
}

type FiltersProps = {
	filters: Filter[]
	activeFilter?: Filter
	setActiveFilter?: (_?: Filter) => void
}

export default function Filters({ filters, activeFilter, setActiveFilter }: FiltersProps) {
	return (
		<div className='Filters'>
			{filters.map(filter => (
				<div 
					className={`Filter ${activeFilter === filter.key ? 'active' : ''}`}
					onClick={() => setActiveFilter ? setActiveFilter(filter.key) : {}}
				>
					{filter.icon}
					{filter.title}
				</div>
			))}
		</div>
	)
}