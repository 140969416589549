import styled from "@emotion/styled"
import { CircularProgress } from "@mui/material"
import React, { CSSProperties, useState } from "react"

type Props = {
	icon: JSX.Element
	text?: string
	color: string
	onPress: () => Promise<any> | void
	style?: CSSProperties
}

const Container = styled.a`
	display: flex;
	flex-direction: row;
	align-items: center;
	& svg {
		width: 24px;
		height: 24px;
		margin-right: 2px;
		fill: ${({ color }: Props) => color};
	}
`

export default function IconButton(props: Props) {
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const _onClick = () => {
		setIsLoading(true)
		let invoke = props.onPress()
		if (invoke instanceof Promise) {
			invoke.finally(() => setIsLoading(false))
		} else {
			setIsLoading(false)
		}
	}

	return (
		<Container {...props} onClick={_onClick}>
			{isLoading && <CircularProgress size={24} />}
			{!isLoading && props.icon}
			<span style={{ marginLeft: 4, color: props.color }}>{props.text && props.text}</span>
		</Container>
	)
}