import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, MenuItem, TextField, FormControl, Autocomplete, LinearProgress, FormControlLabel, Checkbox } from "@mui/material"
import { ReactComponent as IconMyChart } from "../../assets/icons/MyChart.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import ThemedSelect from "../../components/Select"
import { DesktopDatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import { flagEmoji, isValidEmail } from "../../util"
import { isValidPhoneNumber, parsePhoneNumber, PhoneNumber } from 'libphonenumber-js'
import { CovidTestsContext } from "../CovidTests/context"
import { ILoginNavigate, INavigate } from "./"
import countries from "../../countries"
import { Link } from "react-router-dom"
import API from "../../api"
import { CovidTestReason, CreatePatientRequest, Patient, UpdatePatientRequest } from "../../models"
import { AppContext } from "../../context"
import { LoginContext } from "./context"


export default function Register(props: ILoginNavigate) {
	const appCtx = useContext(AppContext)
	const loginCtx = useContext(LoginContext)
	const ctx = useContext(CovidTestsContext)

	const [didNext, setDidNext] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const [phoneValid, setPhoneValid] = useState<boolean>(true)
	const [emailValid, setEmailValid] = useState<boolean>(true)

	const onChangePhone = (text: string) => {
		try {
			let pn = parsePhoneNumber(text, "BM")
			let stripped = text.replace(' ', '')
			if (stripped === '+1441' || stripped === '441') {
				text = ''
			} else if (pn.number.startsWith('+1441')) {
				let intl = pn.formatNational()
				text = intl.replace('(441) ', '')
			} else if (pn.country !== "BM") {
				text = pn.formatInternational()
			}
			ctx.setPhoneNumber(pn)
		} catch {
			ctx.setPhoneNumber(undefined)
		}
		setPhoneValid(isValidPhoneNumber(text, "BM"))
		ctx.setPhoneValue(text)
	}

	useEffect(() => {
		setEmailValid(ctx.email ? ctx.email.length !== 0 && isValidEmail(ctx.email) : true)
	}, [ctx.email])

	const onNext = () => {
		setDidNext(true)

		if (!ctx.firstName || !ctx.lastName || !ctx.sex || !ctx.dateOfBirth || !ctx.dateOfBirth.isValid() || !ctx.phoneValue || !phoneValid || !ctx.email || !emailValid || (ctx.testReason === CovidTestReason.travel && !ctx.passportNumber)) {
			return
		} else if (appCtx.user) {
			onUpdate()
		} else {
			onRegister()
		}
	}

	const onUpdate = () => {
		setIsLoading(true)
		const patient: UpdatePatientRequest = {
			firstName: ctx.firstName!,
			middleName: ctx.middleName!,
			lastName: ctx.lastName!,
			dateOfBirth: ctx.dateOfBirth!.tz('America/Halifax', true).format(),
			sex: ctx.sex!,
			passportNumber: ctx.passportNumber!,
			gpId: ctx.selectedGPId,
			isCovidVaccinated: ctx.vaccinated
		}
		API.updatePatient(patient)
		.then(({ user }) => {
			props.onLogin(user, "register")
		})
		.catch(error => alert("Failed to update details"))
		.finally(() => setIsLoading(false))
	}

	const onRegister = () => {
		setIsLoading(true)
		const patient: CreatePatientRequest = {
			firstName: ctx.firstName!,
			middleName: ctx.middleName!,
			lastName: ctx.lastName!,
			dateOfBirth: ctx.dateOfBirth!.tz('America/Halifax', true).format(),
			sex: ctx.sex!,
			phone: ctx.phoneValue!,
			email: ctx.email!,
			passportNumber: ctx.passportNumber,
			gpId: ctx.selectedGPId,
			isCovidVaccinated: ctx.vaccinated
		}
		API.registerPatient(patient)
		.then(({ user }) => {
			appCtx.setUser(user)
			props.onLogin(user, "register")
		})
		.catch(error => {
			if (error.response.status === 403) {
				if (error.response.data.type === "phone") {
					alert("Phone number already belongs to an account. Please login or use a different number to register.")
					setPhoneValid(false)
				} else if (error.response.data.type === "email") {
					alert("Email address already belongs to an account. Please login or use a different address to register.")
					setEmailValid(false)
				}
			} else {
				alert(error.response.data.message ?? "Failed to register")
			}
		})
		.finally(() => setIsLoading(false))
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>{appCtx.user ? 'Review your details' : 'Patient registration'}</h1>
				<IconMyChart />
			</div>
			{isLoading && <LinearProgress />}
			{!appCtx.user && <div className="Card-message">
				Already have an account?
				<a 
					onClick={() => loginCtx.setMode("login")}
					style={{ marginLeft: 'auto', fontWeight: 700 }}
				>
					Login
				</a>
			</div>}
			<Grid container direction="column" className="Card-form" sx={{ px: 4, pt: 0, pb: 3 }}>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs>
						<Input 
							label="First Name" 
							value={ctx.firstName}
							onChange={e => ctx.setFirstName(e.target.value)}
							autoComplete="given-name"
							error={!ctx.firstName && didNext}
						/>
					</Grid>
					<Grid item xs>
						<Input 
							label="Middle Name" 
							value={ctx.middleName}
							onChange={e => ctx.setMiddleName(e.target.value)}
							autoComplete="middle-name"
						/>
					</Grid>
					<Grid item xs>
						<Input 
							label="Last Name" 
							value={ctx.lastName}
							onChange={e => ctx.setLastName(e.target.value)}
							autoComplete="family-name"
							error={!ctx.lastName && didNext}
						/>
					</Grid>
				</Grid>
				<Grid item container direction="row" spacing={2}>
					<Grid item xs>
						<LocalizationProvider dateAdapter={DateAdapter}>
							<DesktopDatePicker
								disableOpenPicker
								disableFuture
								mask="__/__/____"
								label="Date of birth"
								value={ctx.dateOfBirth}
								onChange={(value) => ctx.setDateOfBirth(value)}
								renderInput={(params) => 
									<Input {...params} 
										inputMode="numeric"
										error={didNext && (!ctx.dateOfBirth || !ctx.dateOfBirth.isValid())} 
									/>
								}
							/>
						</LocalizationProvider>
					</Grid>
					<Grid item xs>
						<ThemedSelect 
							placeholder="Sex" 
							value={ctx.sex} 
							onChange={e => ctx.setSex(e.target.value as string)}
							error={!ctx.sex && didNext}
						>
							<MenuItem value="Male">Male</MenuItem>
							<MenuItem value="Female">Female</MenuItem>
							<MenuItem value="Other">Other</MenuItem>
						</ThemedSelect>
					</Grid>
				</Grid>
				{!appCtx.user && <>
					<Grid item>
						<Input 
							label="Mobile phone number" 
							value={ctx.phoneValue}
							onChange={e => onChangePhone(e.target.value)}
							type="tel"
							inputMode="tel"
							autoComplete="phone"
							/*suffix={phoneNumber && phoneNumber.country ? flagEmoji(phoneNumber.country) : undefined}*/
							/*prefix={(phoneNumber?.country === 'BM') ? "+1 441" : undefined}*/
							error={!phoneValid || (!ctx.phoneValue && didNext)}
							InputProps={{
								startAdornment: ctx.phoneNumber?.country === 'BM' ? (
									<span style={{ color: 'var(--color-text3)', width: 70 }}>
										+1 441
									</span>
								) : undefined,
								endAdornment: ctx.phoneNumber && ctx.phoneNumber.country ? flagEmoji(ctx.phoneNumber.country) : undefined
							}}
							helperText="Type '+' for international numbers"
						/>
					</Grid>
					<Grid item>
						<Input 
							label="Email" 
							value={ctx.email}
							onChange={e => ctx.setEmail(e.target.value)}
							autoComplete="email"
							type="email"
							inputMode="email"
							error={!emailValid || (!ctx.email && didNext)}
						/>
					</Grid>
				</>}
				{ctx.testReason === CovidTestReason.travel && <Grid item>
					<Input 
						label="Passport number" 
						value={ctx.passportNumber}
						onChange={e => ctx.setPassportNumber(e.target.value)}
						error={(ctx.testReason === CovidTestReason.travel && !ctx.passportNumber) && didNext}
						helperText={ctx.passportNumber ? "Required for travel document verification" : undefined}
					/>
				</Grid>}
				<Grid item>
					<Autocomplete 
						key="gp-input"
						clearOnEscape={false}
						clearOnBlur={false}
						options={appCtx.GPs?.sort((a,b) => a.name.split(' ')[1].localeCompare(b.name.split(' ')[1])) ?? []}
						getOptionLabel={opt => opt.name}
						value={appCtx.GPs?.find(x => x.id === ctx.selectedGPId)}
						onChange={(e, value) => ctx.setSelectedGPId(value?.id ?? undefined)}
						renderInput={params => 
							<Input {...params} 
								label="Current GP (optional)"
							/>
						}
					/>
				</Grid>
				<Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
					{props.goBack && <Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>}
					<Grid item xs>
						<ThemedButton onClick={onNext}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
