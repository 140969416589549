import React, { useState, useEffect, useContext } from "react"
import { Grid, Modal } from "@mui/material"
import CloseButton from "../../components/CloseButton"
import { isValidEmail } from "../../util"
import Input from "../../components/Input"
import ThemedButton from "../../components/Button"
import API from "../../api"
import SignatureCanvas from "react-signature-canvas"
import { FacilityUser } from "../../models"
import { FacilityPortalContext } from "./context"

type Props = {
	updateUser?: FacilityUser
	open: boolean
	onClose: (refresh?: boolean) => void
}

export default function CreateUserModal({ updateUser, open, onClose }: Props) {
	const [didSubmit, setDidSubmit] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [email, setEmail] = useState<string | undefined>(updateUser?.email)
	const [emailValid, setEmailValid] = useState<boolean>(false)
	const [name, setName] = useState<string | undefined>(updateUser?.name)
	const [signature, setSignature] = useState<string>()

	const ctx = useContext(FacilityPortalContext)

	const signatureRef = React.createRef<SignatureCanvas>()

	useEffect(() => {
		setEmailValid(email ? email.length !== 0 && isValidEmail(email) : true)
	}, [email])

	const isInvalid = !name || (!updateUser && !email) || (updateUser && !signature)

	const onSubmit = () => {
		setDidSubmit(true)
		if (isInvalid) {
			return
		}

		setIsSubmitting(true)
		if (updateUser) {
			return API.updateFacilityUser(ctx.facility!.id, updateUser.id, name ?? "", email ?? "", signature ?? "")
			.then(() => onClose(true))
			.catch(alert)
			.finally(() => setIsSubmitting(false))
		} else {
			return API.createFacilityUser(ctx.facility!.id, name!, email!)
			.then(() => onClose(true))
			.catch(alert)
			.finally(() => setIsSubmitting(false))
		}
	}

	const clearSignature = (e: any) => {
		e.preventDefault()
		signatureRef.current?.clear()
		setSignature(undefined)
	}

	return (
		<Modal 
			open={open} 
			onClose={() => onClose(false)}
			sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<div className="Card" style={{ maxWidth: 400 }}>
				<CloseButton onClose={() => onClose(false)} />
				<div className="Card-title">
					<h1>{updateUser ? "Update" : "Create"} user</h1> 
				</div>
				<Grid container sx={{ pb: 2, px: 4 }}>
					{!updateUser && ctx.facility!.isAdmin && <>
					<Grid item width="100%">
						<Input 
							label="Email" 
							value={email}
							onChange={e => setEmail(e.target.value)}
							type="email"
							inputMode="email"
							autoComplete="off"
							error={!emailValid || (!email && didSubmit)}
						/>
					</Grid>
					</>}
					<Grid item width="100%">
						<Input 
							label="Name" 
							value={name}
							onChange={e => setName(e.target.value)}
							autoComplete="name"
							error={!name && didSubmit}
						/>
					</Grid>
					{updateUser && ctx.user!.id === updateUser.id && <Grid item className='SignatureBox'>
						<span>Signature <a onClick={clearSignature}>clear</a></span>
						<SignatureCanvas 
							ref={signatureRef}
							onEnd={() => setSignature(signatureRef.current!.toDataURL())}
							canvasProps={{
								width: 400,
								height: 150,
							}}
						/>
					</Grid>}
					<ThemedButton 
						style={{ marginTop: 24 }} 
						onClick={onSubmit}
						disabled={isSubmitting || isInvalid}
					>
						{updateUser ? "UPDATE" : "CREATE"} USER
					</ThemedButton>
				</Grid>
			</div>
		</Modal>
	)
}