import styled from '@emotion/styled'
import { Modal } from '@mui/material'
import React, { useState } from 'react'
import { ReactComponent as IconClose } from '../assets/icons/Close.svg'
import Contact from './Contact'
import Privacy from './Privacy'


const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 16px;
	color: var(--color-text2);
	font-size: 14px;
	z-index: 99;
	@media(max-width: 480px) {
		justify-content: center;
	}
	position: fixed;
	bottom: 0;
`

const ModalCard = styled.div`
	outline: none;
	padding: 24px;
	background-color: white;
	border-radius: 10px;
	box-shadow: var(--shadow);
	& .row {
		margin-bottom: 16px;
	}
	max-width: 400px;
	position: absolute;
	left: 50%;
	margin: 100px 0;
	transform: translateX(-50%);
	& p, h2, h3, h4, li {
		padding: 8px 0;
	}
	& ul, ol {
		padding-left: 20px;
	}
`

export default function Footer() {
	const pipe = <span style={{ marginLeft: 12, marginRight: 12, color: 'var(--color-text3)' }}>|</span>

	const [privacyOpen, setPrivacyOpen] = useState<boolean>(false)
	const [contactOpen, setContactOpen] = useState<boolean>(false)

	return (<Container>
		<span>Copyright &copy; 2022 <a href="https://www.bhec.bm" target="_blank">BHeC</a></span>
		{/*
		{pipe}
		<span>website by <a href="https://www.gdev.bm" target="_blank">gdev.bm</a></span>
		*/}
		{pipe}
		<span><a onClick={() => setPrivacyOpen(true)}>Privacy</a></span>
		{pipe}
		<span><a onClick={() => setContactOpen(true)}>Contact</a></span>

		<Modal open={privacyOpen} onClose={() => setPrivacyOpen(false)} className="Modal">
			<ModalCard>
				<div className='row'>
					<h1>Privacy</h1>
					<a onClick={() => setPrivacyOpen(false)} style={{ marginLeft: 'auto' }}>
						<IconClose fill="var(--color-text3)" width={28} height={28} />
					</a>
				</div>
				<Privacy />
			</ModalCard>
		</Modal>

		<Modal open={contactOpen} onClose={() => setContactOpen(false)} className="Modal">
			<ModalCard>
				<div className='row'>
					<h1>Contact</h1>
					<a onClick={() => setContactOpen(false)} style={{ marginLeft: 'auto' }}>
						<IconClose fill="var(--color-text3)" width={28} height={28} />
					</a>
				</div>
				<Contact />
			</ModalCard>
		</Modal>
	</Container>)
}