import { LinearProgress } from "@mui/material"
import React, { useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import API from "../../api"
import { ReactComponent as IconCertificateColor } from "../../assets/icons/CertificateColor.svg"
import { ReactComponent as IconInvalid } from "../../assets/icons/Invalid.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { VerifyContext } from "./context"


export default function Lookup() {
	const ctx = useContext(VerifyContext)
	const history = useHistory()

	const [certificateNumber, setCertificateNumber] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isInvalid, setIsInvalid] = useState<boolean>(false)

	const onLookup = async () => {
		setIsLoading(true)
		try {
			const { certificate } = await API.verifyCertificate(certificateNumber)
			ctx.setCertificate(certificate)
			history.push(`/verify/${certificateNumber}`)
		} catch {
			setIsInvalid(true)
		} finally {
			setIsLoading(false)
		}
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>
					{!isInvalid && "Verify a certificate"}
					{isInvalid && "Invalid certificate"}
				</h1>
				{!isInvalid && <IconCertificateColor />}
				{isInvalid && <IconInvalid />}
			</div>
			{isLoading && <LinearProgress />}
			<div className="Card-message">
				{!isInvalid && "You can also scan a certificate's QR code for faster verification."}
				{isInvalid && "No certificate found with this number. Try scanning the QR code or entering manually below."}
			</div>
			<div className="Card-form" style={{ padding: 32, paddingTop: 0 }}>
				<Input 
					label="Certificate number" 
					value={certificateNumber}
					onChange={e => setCertificateNumber(e.target.value.toUpperCase())}
				/>
				<ThemedButton style={{ marginTop: 24 }} onClick={onLookup}>VERIFY</ThemedButton>
			</div>
		</div>
	)
}
