import React, { useEffect, useState } from "react"
import { Facility, CovidTestBooking, User, FacilityUser, Patient, GP } from "./models"
import API from "./api"
import { useHistory } from "react-router-dom"

export type State = {
	didBootstrap: boolean
	setDidBootstrap: (_: boolean) => void
	user?: User | Patient | FacilityUser
	setUser: (_?: Patient | FacilityUser) => void
	logout: () => void
	covidTestBooking?: CovidTestBooking
	setCovidTestBooking: (_: CovidTestBooking) => void
	GPs?: GP[]
	setGPs: (_: GP[]) => void
	facilities: Facility[]
}

export const AppContext = React.createContext<State>({
	didBootstrap: false,
	setDidBootstrap: () => {},
	user: undefined,
	setUser: () => {},
	logout: () => {},
	covidTestBooking: undefined,
	setCovidTestBooking: () => {},
	facilities: [],
	GPs: undefined,
	setGPs: () => {},
})

export default function AppProvider(props: React.PropsWithChildren<{}>) {
	const [didBootstrap, setDidBootstrap] = useState<boolean>(false)
	const [token, setTokenValue] = useState<string>()
	const [user, setUser] = useState<User | Patient | FacilityUser>()
	const [covidTestBooking, setCovidTestBooking] = useState<CovidTestBooking>()

	const [GPs, setGPs] = useState<GP[]>()
	const [facilities, setFacilities] = useState<Facility[]>([])

	useEffect(() => {
		init()
	}, [])

	const init = async () => {
		try {
			const { user } = await API.getUser()
			setUser(user)
		} catch {}

		let { facilities, gps } = await API.init()
		setFacilities(facilities)
		setGPs(gps)

		setDidBootstrap(true)
	}

	const logout = () => {
		setUser(undefined)
		localStorage.removeItem('USER_TOKEN')
		localStorage.removeItem('REFRESH_TOKEN')
		window.location.reload()
	}

	return (
		<AppContext.Provider value={{
			didBootstrap, setDidBootstrap,
			user, setUser,
			logout,
			covidTestBooking, setCovidTestBooking,
			GPs, setGPs,
			facilities
		}}>
			{props.children}
		</AppContext.Provider>
	)
}