import React, { useContext, useEffect, useMemo, useState } from "react"
import { CovidTestReason, Facility, Patient, PaymentType, GP } from "../../models"
import moment from "moment"
import API from "../../api"
import { parsePhoneNumber, PhoneNumber } from "libphonenumber-js"
import { AppContext } from "../../context"

export type State = {
	testReason: CovidTestReason
	setTestReason: (_: CovidTestReason) => void
	isTelehealth: boolean
	setIsTelehealth: (_: boolean) => void

	selectedGPId?: number
	setSelectedGPId: (_: number | undefined) => void

	firstName?: string
	setFirstName: (_: string) => void
	middleName?: string
	setMiddleName: (_: string) => void
	lastName?: string
	setLastName: (_: string) => void
	dateOfBirth: moment.Moment | null
	setDateOfBirth: (_: moment.Moment | null) => void,
	sex?: string
	setSex: (_: string) => void
	phoneValue?: string
	setPhoneValue: (_: string) => void
	phoneNumber?: PhoneNumber
	setPhoneNumber: (_?: PhoneNumber) => void
	email?: string
	setEmail: (_: string) => void
	passportNumber?: string
	setPassportNumber: (_: string) => void
	
	homeAddress?: string
	setHomeAddress: (_: string) => void
	parish?: string
	setParish: (_?: string) => void
	postcode?: string
	setPostcode: (_: string) => void
	country?: string
	setCountry: (_?: string) => void

	selectedDependentIds: number[]
	selectDependent: (_: Patient) => void
	deselectDependent: (_: Patient) => void
	numBookings: number

	facilities: Facility[]
	facility?: Facility
	setFacility: (_?: Facility) => void
	date: moment.Moment | null
	setDate: (_: moment.Moment | null) => void

	attest: boolean
	setAttest: (_: boolean) => void
	vaccinated: boolean
	setVaccinated: (_: boolean) => void
	signature?: string
	setSignature: (_?: string) => void

	payNow: boolean
	setPayNow: (_: boolean) => void
	cardNumber?: string
	setCardNumber: (_: string) => void
	cardholderName?: string
	setCardholderName: (_: string) => void
	expiration?: string
	setExpiration: (_: string) => void
	securityCode?: string
	setSecurityCode: (_: string) => void
	cardType?: PaymentType
	setCardType: (_?: PaymentType) => void
}

export const CovidTestsContext = React.createContext<State>({
	testReason: CovidTestReason.travel,
	setTestReason: () => {},
	isTelehealth: false,
	setIsTelehealth: () => {},

	selectedGPId: undefined,
	setSelectedGPId: () => {},

	firstName: undefined,
	setFirstName: () => {},
	middleName: undefined,
	setMiddleName: () => {},
	lastName: undefined,
	setLastName: () => {},
	dateOfBirth: null,
	setDateOfBirth: () => {},
	sex: undefined,
	setSex: () => {},
	phoneValue: undefined,
	setPhoneValue: () => {},
	phoneNumber: undefined,
	setPhoneNumber: () => {},
	email: undefined,
	setEmail: () => {},
	passportNumber: undefined,
	setPassportNumber: () => {},
	homeAddress: undefined,
	setHomeAddress: () => {},
	parish: undefined,
	setParish: () => {},
	postcode: undefined,
	setPostcode: () => {},
	country: "BM",
	setCountry: () => {},

	selectedDependentIds: [],
	selectDependent: () => {},
	deselectDependent: () => {},
	numBookings: 1,

	facilities: [],
	facility: undefined,
	setFacility: () => {},
	date: null,
	setDate: () => {},

	attest: false,
	setAttest: () => {},
	vaccinated: false,
	setVaccinated: () => {},
	signature: undefined,
	setSignature: () => {},

	payNow: false,
	setPayNow: () => {},
	cardNumber: undefined,
	setCardNumber: () => {},
	cardholderName: undefined,
	setCardholderName: () => {},
	expiration: undefined,
	setExpiration: () => {},
	securityCode: undefined,
	setSecurityCode: () => {},
	cardType: undefined,
	setCardType: () => {},
})

export default function CovidTestsProvider(props: React.PropsWithChildren<{}>) {
	const appCtx = useContext(AppContext)
	
	const [testReason, setTestReason] = useState<CovidTestReason>(CovidTestReason.travel)
	const [isTelehealth, setIsTelehealth] = useState<boolean>(false)
	const [selectedGPId, setSelectedGPId] = useState<number>()
	
	const [firstName, setFirstName] = useState<string>()
	const [middleName, setMiddleName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [dateOfBirth, setDateOfBirth] = useState<moment.Moment | null>(null)
	const [sex, setSex] = useState<string>()
	const [phoneValue, setPhoneValue] = useState<string>()
	const [phoneNumber, setPhoneNumber] = useState<PhoneNumber>()
	const [email, setEmail] = useState<string>()
	const [passportNumber, setPassportNumber] = useState<string>()
	
	useEffect(() => {
		if (appCtx.user?.type === "patient") {
			const patient = appCtx.user as Patient
			console.log(patient)
			setFirstName(patient.firstName)
			setMiddleName(patient.middleName)
			setLastName(patient.lastName)
			setDateOfBirth(moment(patient.dateOfBirth))
			setSex(patient.sex)
			let pn = parsePhoneNumber(patient.phone, "BM")
			setPhoneValue(pn.formatInternational())
			setPhoneNumber(pn)
			setEmail(patient.email)
			setPassportNumber(patient.passportNumber)
			setSelectedGPId(patient.gpId)
			if (patient.address) {
				setHomeAddress(patient.address.address)
				setParish(patient.address.parish)
				setPostcode(patient.address.postcode)
				setCountry(patient.address.country)
			}
		}
	}, [appCtx.user])

	const [homeAddress, setHomeAddress] = useState<string>()
	const [parish, setParish] = useState<string>()
	const [postcode, setPostcode] = useState<string>()
	const [country, setCountry] = useState<string | undefined>("BM")

	const [selectedDependentIds, setSelectedDependentIds] = useState<number[]>([])

	const selectDependent = (patient: Patient) => {
		const temp = selectedDependentIds.filter(x => x !== patient.id)
		setSelectedDependentIds([ ...temp, patient.id ])
	}

	const deselectDependent = (patient: Patient) => {
		setSelectedDependentIds(selectedDependentIds.filter(x => x !== patient.id))
	}

	const numBookings = 1 + selectedDependentIds.length

	const facilities = useMemo(() => (
		appCtx.facilities.filter(x => x.isCovidLive && (isTelehealth ? x.isTelehealth : true))
	), [appCtx.facilities, isTelehealth])

	const [facility, setFacility] = useState<Facility>()
	const [date, setDate] = useState<moment.Moment | null>(null)

	const [attest, setAttest] = useState<boolean>(false)
	const [vaccinated, setVaccinated] = useState<boolean>(false)
	const [signature, setSignature] = useState<string>()

	const [payNow, setPayNow] = useState<boolean>(false)
	const [cardNumber, setCardNumber] = useState<string>()
	const [cardholderName, setCardholderName] = useState<string>()
	const [expiration, setExpiration] = useState<string>("")
	const [securityCode, setSecurityCode] = useState<string>("")
	const [cardType, setCardType] = useState<PaymentType>()

	return (
		<CovidTestsContext.Provider value={{
			testReason, setTestReason,
			isTelehealth, setIsTelehealth,
			selectedGPId, setSelectedGPId,

			firstName, setFirstName,
			middleName, setMiddleName,
			lastName, setLastName,
			dateOfBirth, setDateOfBirth,
			sex, setSex,
			phoneValue, setPhoneValue,
			phoneNumber, setPhoneNumber,
			email, setEmail,
			passportNumber, setPassportNumber,
			homeAddress, setHomeAddress,
			parish, setParish,
			postcode, setPostcode,
			country, setCountry,

			selectedDependentIds,
			selectDependent, deselectDependent,
			numBookings,

			facilities,
			facility, setFacility,
			date, setDate,

			attest, setAttest,
			vaccinated, setVaccinated,
			signature, setSignature,

			payNow, setPayNow,
			cardNumber, setCardNumber,
			cardholderName, setCardholderName,
			expiration, setExpiration,
			securityCode, setSecurityCode,
			cardType, setCardType
		}}>
			{props.children}
		</CovidTestsContext.Provider>
	)
}