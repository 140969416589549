import { Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from '@mui/material'
import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import API from '../../api'
import { AppContext } from '../../context'
import { formatAddress, CovidTestBooking, CovidTestResult, Booking } from '../../models'
import Card from './Card'
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams, GridValueFormatterParams } from '@mui/x-data-grid'
import './index.css'
import ThemedButton from '../../components/Button'
import { capitalizeFirstLetter } from '../../util'
import { ReactComponent as IconRefresh } from "../../assets/icons/Refresh.svg"
import { ReactComponent as IconCertificate } from "../../assets/icons/Certificate.svg"
import { ReactComponent as IconPlus } from "../../assets/icons/Plus.svg"
import { ReactComponent as IconCancel } from "../../assets/icons/Cancel.svg"
import { ReactComponent as IconEdit } from "../../assets/icons/Edit.svg"
import NoRows from '../../components/NoRows'
import ActionCol from '../../grid/ActionCol'
import IconButton from '../../components/IconButton'
import { FacilityPortalContext } from './context'
import Input from '../../components/Input'


type CovidTestBookingProps = {
	activeDate: moment.Moment | undefined
}

export default function CovidTestBookings(props: CovidTestBookingProps) {
	const [activeCovidTestBooking, setActiveCovidTestBooking] = useState<CovidTestBooking>()
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [covidTestBookings, setCovidTestBookings] = useState<CovidTestBooking[]>()
	const [cancelBooking, setCancelBooking] = useState<Booking>()
	const [cancelReason, setCancelReason] = useState<string>()

	const history = useHistory()
	const appCtx = useContext(AppContext)
	const ctx = useContext(FacilityPortalContext)

	useEffect(() => {
		load()
	}, [props.activeDate])

	const testResultModal = () => (
		<Modal 
			open={!!activeCovidTestBooking} 
			onClose={() => setActiveCovidTestBooking(undefined)}
			sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Card 
				booking={activeCovidTestBooking}
				refresh={() => {
					setActiveCovidTestBooking(undefined)
					load()
				}}
			/>
		</Modal>
	)

	const load = () => {		
		setIsLoading(true)
		API.getCovidTestBookingsOnDate(ctx.facility!.id, props.activeDate)
		.then(({ bookings }) => setCovidTestBookings(bookings))
		.catch(error => {
			console.error(error)
		})
		.finally(() => setIsLoading(false))
	}

	const columns: any[] = [
		{
			field: 'id',
			headerName: 'ID'
		},
		{
			field: 'isTelehealth',
			headerName: 'Type',
			width: 100,
			valueGetter: ({ row }: GridRowParams) => row.isTelehealth ? "Virtual" : "In-Person"
		},
		{
			field: 'reservationDate',
			headerName: 'Time',
			width: 150,
			type: 'date',
			valueFormatter: ({ value }: GridValueFormatterParams) => moment(value?.toString()).format('MMM Do, h:mm a')
		},
		{
			field: 'testResult',
			headerName: 'Test Result',
			width: 150,
			renderCell: ({ row }: GridRowParams) => {
				if (row.isCancelled) {
					return <Chip color="secondary" label="Cancelled" />
				}

				let testResult: CovidTestResult | null
				if (row.testResult === null) {
					testResult = null
				} else {
					testResult = CovidTestResult[row.testResult as keyof typeof CovidTestResult]
				} 

				if (testResult === null) {
					return <Chip color="default" label="Not completed" />
				} else if (testResult !== null && !row.hasCertificate) {
					return <Chip color="info" label="Processing" />
				}
				return <TestResultChip testResult={testResult} />
			}
		},
		{
			field: 'patient',
			headerName: 'Name',
			width: 150,
			valueGetter: ({ row }: GridRowParams) => `${row.patient.firstName} ${row.patient.lastName}`
		},
		{
			field: 'dateOfBirth',
			headerName: 'Date of Birth',
			width: 120,
			valueGetter: ({ row }: GridRowParams) => moment(row.patient.dateOfBirth).format('MMM Do, YYYY')
		},
		{
			field: 'passportNumber',
			headerName: 'Passport No.',
			width: 130,
			valueGetter: ({ row }: GridRowParams) => row.patient.passportNumber
		},
		{
			field: 'address',
			headerName: 'Address',
			width: 250,
			valueGetter: ({ row }: GridRowParams) => formatAddress(row.patient.address)
		},
		{
			field: 'phone',
			headerName: 'Phone number',
			width: 140,
			valueGetter: ({ row }: GridRowParams) => row.patient.phone
		},
		{
			field: 'email',
			headerName: 'Email address',
			width: 220,
			valueGetter: ({ row }: GridRowParams) => row.patient.email
		},
		{
			field: 'actions',
			type: 'actions',
			renderHeader: () => (
				<div style={{ marginLeft: 'auto', marginRight: 16 }}>
					<GridActionsCellItem 
						icon={<IconRefresh width={24} height={24} fill="var(--color-theme)" />}
						onClick={load}
						label="Refresh"
					/>
				</div>
			),
			align: 'right',
			flex: 1,
			minWidth: 100,
			headerAlign: 'right',
			getActions: ({ row }: GridRowParams) => {
				let actions = []
				if (row.testResult === null && !row.isCancelled) {
					actions.push(
						<GridActionsCellItem 
							icon={<IconPlus fill="var(--color-theme)" width={24} height={24} />}
							onClick={() => openTestResultModal(row)}
							label="Submit Result"
							//showInMenu
						/>
					)
					actions.push(
						<GridActionsCellItem 
							icon={<IconCancel fill="var(--color-red)" width={24} height={24} />}
							onClick={() => setCancelBooking(row)}
							label="Cancel"
							showInMenu
						/>
					)
				}
				if (row.testResult) {
					actions.push(
						<GridActionsCellItem 
							icon={<IconEdit fill="var(--color-theme)" width={24} height={24} />}
							onClick={() => openTestResultModal(row)}
							label="Re-submit"
							showInMenu
						/>
					)
				}
				if (row.hasCertificate) {
					actions.push(
						<GridActionsCellItem 
							icon={<IconCertificate fill="var(--color-theme)" width={24} height={24} />}
							onClick={() => downloadCertificate(row)}
							label="Certificate"
							showInMenu
						/>
					)
				}
				return actions
			}
		}
	]

	const downloadCertificate = (booking: CovidTestBooking): Promise<any> => {
		return API.getCertificateByFacilityUser(booking)
		.then(({ certificate }) => {
			console.log('cert', certificate)
			window.open(certificate.url, '_blank')?.focus()
		})
		.catch(alert)
	}

	const TestResultChip = ({ testResult }: { testResult: CovidTestResult }) => {
		const text = capitalizeFirstLetter(testResult.toString())
		switch (testResult) {
			case CovidTestResult.negative: 
				return <Chip color="success" label={text} />
			case CovidTestResult.inconclusive:
				return <Chip color="warning" label={text} />
			case CovidTestResult.positive: 
				return <Chip color="error" label={text} />
		}
	}

	const openTestResultModal = (row: CovidTestBooking) => {
		setActiveCovidTestBooking(row)
	}

	const onCancelBooking = (): Promise<any> => {
		return API.cancelBooking(cancelBooking!.id, cancelReason || "N/A")
		.then(() => {
			setCancelBooking(undefined)
			setCancelReason(undefined)
			load()
		})
		.catch(alert)
	}

	return (
		<div className='FacilityPortal-grid'>
			{testResultModal()}
			<Dialog open={!!cancelBooking} onClose={() => setCancelBooking(undefined)}>
				{cancelBooking && <>
					<DialogTitle>Cancel COVID test booking {cancelBooking.id}</DialogTitle>
					<DialogContent>
						<DialogContentText>
							{cancelBooking.patient.firstName} {cancelBooking.patient.lastName} will be notified via SMS and email
						</DialogContentText>
						<Input 
							label="Cancellation reason"
							fullWidth
							value={cancelReason}
							onChange={e => setCancelReason(e.target.value)}
							sx={{ mb: 1 }}
						/>
						<DialogActions>
							<ThemedButton variant="text" onClick={() => setCancelBooking(undefined)}>
								BACK
							</ThemedButton>
							<ThemedButton style={{ backgroundColor: 'var(--color-red)' }} onClick={onCancelBooking}>
								CANCEL BOOKING
							</ThemedButton>
						</DialogActions>
					</DialogContent>
				</>}
			</Dialog>
			{isLoading && <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 32 }} />}
			{!isLoading && covidTestBookings && <DataGrid 
				rows={covidTestBookings}
				columns={columns}
				sortModel={[{
					field: 'reservationDate',
					sort: 'asc'
				}]}
				sx={{ 
					backgroundColor: 'white'
				}}
				components={{
					NoRowsOverlay: () => NoRows("bookings")
				}}
				rowsPerPageOptions={[]}
			/>}
		</div>
	)
}