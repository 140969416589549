import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, MenuItem, TextField, FormControl, RadioGroup, FormControlLabel, Radio, LinearProgress } from "@mui/material"
import { ReactComponent as IconPayment } from "../../assets/icons/PaymentMethod.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { determineCardType, formatCardExpiration, formatCardNumber, formatCurrency } from "../../util"
import { getPaymentTypeIcon, PaymentType } from "../../models"
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import { AppContext } from "../../context"
import { useHistory } from "react-router-dom"
import { DEFAULT_COVID_TEST_COST } from "../../const"

export default function PaymentMethod(props: INavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(CovidTestsContext)

	const [didNext, setDidNext] = useState<boolean>(false)
	const [isBooking, setIsBooking] = useState<boolean>(false)

	useEffect(() => {
		if (ctx.cardNumber) {
			ctx.setCardType(determineCardType(ctx.cardNumber))
		}
	}, [ctx.cardNumber])

	const cardNumberIsValid = (ctx.cardNumber && ctx.cardNumber.replaceAll(' ', '').length === 16)
	const expirationIsValid = (ctx.expiration && ctx.expiration.replaceAll(' ', '').length === 5)
	const securityCodeIsValid = (ctx.securityCode && ctx.securityCode.length === 3)

	const onSubmit = () => {
		setDidNext(true)
		if (ctx.payNow && (!cardNumberIsValid || !expirationIsValid || !securityCodeIsValid)) {
			return
		} else {
			props.goNext()
		}
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Payment</h1>
				<IconPayment />
			</div>
			<div className="Card-message">
				Total
				<b style={{ marginLeft: 'auto', color: 'var(--color-text)' }}>{formatCurrency(ctx.numBookings * (ctx.facility?.covidTestCost ?? DEFAULT_COVID_TEST_COST))}</b>
			</div>
			<Grid container direction="column" className="Card-form" sx={{ p: 4, py: 1 }}>
				<RadioGroup row value={ctx.payNow} onChange={e => ctx.setPayNow(e.target.value === "true")} sx={{ pb: 2 }}>
					{/* <FormControlLabel value="true" control={<Radio />} label="Pay now" /> */}
					<FormControlLabel value="false" control={<Radio />} label="Pay at time of test" />
				</RadioGroup>
				{ctx.payNow && <>
					<Grid item>
						<Input 
							label="Card number" 
							placeholder="•••• •••• •••• ••••"
							autoComplete="cc-number"
							value={ctx.cardNumber}
							inputMode="numeric"
							onChange={e => ctx.setCardNumber(formatCardNumber(e.target.value))}
							InputProps={{
								endAdornment: ctx.cardType !== undefined ? getPaymentTypeIcon(ctx.cardType, 32) : undefined
							}}
							sx={{ mt: 0 }}
							error={didNext && !cardNumberIsValid}
						/>
					</Grid>
					<Grid item container direction="row" spacing={2}>
						<Grid item xs>
							<Input 
								label="Expiration" 
								placeholder="MM / YY"
								inputMode="numeric"
								autoComplete="cc-exp"
								value={ctx.expiration}
								onChange={e => ctx.setExpiration(formatCardExpiration(e.target.value))}
								error={didNext && !expirationIsValid}
							/>
						</Grid>
						<Grid item xs>
							<Input 
								label="Security code"
								placeholder="•••"
								inputMode="numeric"
								autoComplete="cc-csc"
								value={ctx.securityCode}
								onChange={e => ctx.setSecurityCode(e.target.value.substring(0, 3))}
								error={didNext && !securityCodeIsValid}
							/>
						</Grid>
					</Grid>
					<Grid item>
						<Input 
							label="Cardholder name"
							autoComplete="cc-name"
							value={ctx.cardholderName}
							onChange={e => ctx.setCardholderName(e.target.value)}
							error={didNext && (!ctx.cardholderName)}
						/>
					</Grid>
				</>}

				<Grid container direction="row" spacing={2} sx={{ my: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={onSubmit}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
