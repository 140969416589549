import React, { useContext, useEffect, useState } from "react"
import { ReactComponent as IconBooked } from "../assets/icons/Booked.svg"
import { useHistory, useParams } from 'react-router-dom'
import { AppContext } from "../context"
import API from "../api"
import { LinearProgress } from "@mui/material"
import moment from "moment"
import { CovidTestBooking, formatAddress } from "../models"
import { ReactComponent as IconLocation } from "../assets/icons/Location.svg"
import { ReactComponent as IconVideo } from "../assets/icons/Video.svg"

export default function Booking() {
	const ctx = useContext(AppContext)
	const { confirmationNumber } = useParams<{ confirmationNumber: string }>()

	const history = useHistory()

	const [isLoading, setIsLoading] = useState<boolean>(false)

	useEffect(() => {
		if (confirmationNumber.length === 0) {
			history.push('/certificate')
			return
		}

		if (!ctx.covidTestBooking) {
			setIsLoading(true)
			API.getBooking(confirmationNumber)
			.then(({ booking }) => {
				if (booking.type === "covid_test_booking") {
					const covidTestBooking: CovidTestBooking = booking
					ctx.setCovidTestBooking(covidTestBooking)
				}
			})
			.catch(error => {
				console.error(error)
				history.push('/')
			})
			.finally(() => {
				setIsLoading(false)
			})
		}
	}, [])

	return (<div className="Slides">
		<div className="Card">
			<div className="Card-title" style={{ padding: 24, paddingBottom: 8 }}>
				<h1>{isLoading ? 'Loading...' : 'Test Booked'}</h1>
				{!isLoading && <IconBooked />}
			</div>
			{isLoading && <LinearProgress />}
			{!isLoading && ctx.covidTestBooking && <>
				<div className="Card-message">
					Confirmation number
					<b style={{ marginLeft: 'auto', color: 'var(--color-text)' }}>{ctx.covidTestBooking.id}</b>
				</div>
				<div style={{ padding: 32, paddingTop: 16 }}>
					<p>You have reserved a test slot at <b>{moment(ctx.covidTestBooking.reservationDate).format('h:mm a')}</b> on <b>{moment(ctx.covidTestBooking.reservationDate).format('MMMM Do, yyyy')}</b>. Booking confirmation has also been emailed and texted to you.</p>
					<br />
					<div className='Address'>
						{ctx.covidTestBooking.isTelehealth ? <IconVideo fill="var(--color-border)" width={44} /> : <IconLocation fill="var(--color-border)" width={44} />}
						<div>
							<b>{ctx.covidTestBooking.facility.name}</b>
							{!ctx.covidTestBooking.isTelehealth && <div>{formatAddress(ctx.covidTestBooking.facility.address)}</div>}
							{ctx.covidTestBooking.isTelehealth && <div>Virtual</div>}
						</div>
					</div>
					<br />
					<p>Please remember to bring photo ID for your appointment. Once your test results are processed, your COVID test result certificate will be sent to you via email and SMS.</p>
				</div>
			</>}
		</div>
	</div>)
}
