import moment from "moment"
import React, { useContext, useState } from "react"
import ThemedButton from "../../components/Button"
import { CovidTestBooking, CovidTestResult, CovidTestType } from "../../models"
import { ReactComponent as IconTick } from '../../assets/icons/Tick.svg'
import { ReactComponent as IconInvalid } from '../../assets/icons/Invalid.svg'
import { ReactComponent as IconFailure } from '../../assets/icons/Failure.svg'
import { ReactComponent as IconPaymentRequired } from '../../assets/icons/PaymentRequired.svg'
import './Card.css'
import API from "../../api"
import { capitalizeFirstLetter, formatCurrency } from "../../util"
import { Checkbox, FormControlLabel, Grid, LinearProgress, MenuItem } from "@mui/material"
import ThemedSelect from "../../components/Select"
import { FacilityPortalContext } from "./context"
import { DEFAULT_COVID_TEST_COST } from "../../const"


type Props = {
	booking?: CovidTestBooking
	refresh: () => void
}

export default function Card({ booking, refresh }: Props) {
	const ctx = useContext(FacilityPortalContext)

	const [isConfirmed, setIsConfirmed] = useState<boolean>(false)
	const [didPay, setDidPay] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	const [testResult, setTestResult] = useState<CovidTestResult | undefined>(booking?.testResult)
	const [testType, setTestType] = useState<CovidTestType>(CovidTestType.antigen)

	if (booking === undefined) {
		return <span />
	}

	const onSubmit = () => {
		if (isSubmitting || testResult === undefined) return

		setIsSubmitting(true)
		API.submitTestResult(booking.id, testResult, testType)
		.then(refresh)
		.catch(error => {
			console.error(error)
			alert(error.response?.data?.message ?? 'Failed to submit test result.')
		})
		.finally(() => setIsSubmitting(false))
	}

	const testResultIcon = (testResult: CovidTestResult) => {
		switch (testResult) {
			case CovidTestResult.negative: return <IconTick width={44} height={44} />
			case CovidTestResult.inconclusive: return <IconInvalid width={44} height={44} />
			case CovidTestResult.positive: return <IconFailure width={44} height={44} />
		}
	}

	return (
		<div className="Card" style={{ maxWidth: 400 }}>
			<div className="Card-header">
				ORDER #<b style={{ color: 'var(--color-text2)' }}>{booking.id}</b>
			</div>
			{isSubmitting && <LinearProgress />}
			<div className="Card-row">
				<div>
					<div className="subtitle">Traveler name</div>
					<div>{booking.patient.firstName} {booking.patient.lastName}</div>
				</div>
				<div style={{ marginLeft: 'auto', textAlign: 'right' }}>
					<div className="subtitle">Date of Birth</div>
					<div>{moment(booking.patient.dateOfBirth).format('DD/MM/YYYY')}</div>
				</div>
			</div>
			<div className="Card-row" style={{ paddingTop: 0, color: 'var(--color-red)' }}>
				<IconPaymentRequired style={{ marginRight: 8 }} />
				Requires payment of {formatCurrency(booking.price)} BMD
			</div>
			<div className="Card-message" style={{ marginTop: 0 }}>
				Time slot
				<b style={{ marginLeft: 'auto', color: 'var(--color-text)' }}>{moment(booking.reservationDate).format('h:mm a')}</b>
			</div>
			<Grid container sx={{ py: 2, px: 4 }}>
				<div className="subtext" style={{ marginBottom: 16 }}>Select COVID-19 Antigen Test Result</div>
				<Grid container spacing={1} sx={{ mb: 1 }}>
					{[CovidTestResult.negative, CovidTestResult.inconclusive, CovidTestResult.positive].map(result => 
						<Grid item xs={4} >
							<a 
								className={`TestResult ${CovidTestResult[result]} ${testResult === result ? 'active' : ''}`}
								onClick={() => setTestResult(result)}
							>
								{testResultIcon(result)}
								{capitalizeFirstLetter(CovidTestResult[result])}
							</a>
						</Grid>
					)}
				</Grid>
				<Grid item xs sx={{ mb: 2 }}>
					<ThemedSelect 
						placeholder="Test type" 
						value={CovidTestType[testType]} 
						onChange={e => setTestType(e.target.value as CovidTestType)}
					>
						<MenuItem value="antigen">Antigen</MenuItem>
						<MenuItem value="naat">Nucleic Acid Ampleification Test (NAAT)</MenuItem>
					</ThemedSelect>
				</Grid>
				{testResult !== null && <Grid container>
					<Grid item>
						<FormControlLabel className='Checkbox' control={<Checkbox size="medium" value={isConfirmed} onChange={e => setIsConfirmed(e.target.checked)} />} label="I confirm the validity of this test result, and I have verified the patient's identification" />
					</Grid>
					{!booking.isPaid && <Grid item>
						<FormControlLabel className='Checkbox' control={<Checkbox size="medium" value={didPay} onChange={e => setDidPay(e.target.checked)} />} label={`I confirm payment by traveler of ${formatCurrency(booking.price)} BMD`} />
					</Grid>}
					<Grid item xs sx={{ mt: 1 }}>
						<ThemedButton onClick={onSubmit} disabled={isSubmitting || !isConfirmed || (!booking.isPaid && !didPay)}>
							SUBMIT
						</ThemedButton>
					</Grid>
				</Grid>}
			</Grid>
		</div>
	)
}