import React, { useContext, useState, useEffect } from "react"
import { Checkbox, FormControlLabel, Grid } from "@mui/material"
import { ReactComponent as IconAgreement } from "../../assets/icons/Agreement.svg"
import ThemedButton from "../../components/Button"
import { CovidTestsContext } from "./context"
import { INavigate } from "."
import SignatureCanvas from "react-signature-canvas"


export default function Agreement(props: INavigate) {
	const ctx = useContext(CovidTestsContext)

	const cardRef = React.createRef<HTMLDivElement>()
	const signatureRef = React.createRef<SignatureCanvas>()

	const clearSignature = (e?: any) => {
		e?.preventDefault()
		signatureRef.current?.clear()
		ctx.setSignature(undefined)
	}

	useEffect(()=> {
		clearSignature()
	}, [])

	return (
		<div className="Card" ref={cardRef}>
			<div className="Card-title">
				<h1>Agreement</h1>
				<IconAgreement />
			</div>
			<div className="Card-message">
				Your information will be used to fill out legal travel documents.
			</div>
			<Grid className="Card-form" container sx={{ px: 4, py: 2 }}>
				<Grid item>
					<FormControlLabel control={
						<Checkbox 
							size="medium" 
							value={ctx.attest} 
							checked={ctx.attest}
							onChange={e => ctx.setAttest(e.target.checked)} 
						/>} 
						label="I attest that the information provided so far has been accurate, to the best of my knowledge." 
					/>
				</Grid>
				<Grid item>
					<FormControlLabel control={
						<Checkbox 
							size="medium" 
							value={ctx.vaccinated} 
							checked={ctx.vaccinated}
							onChange={e => ctx.setVaccinated(e.target.checked)}
						/>} 
						label="I am vaccinated against COVID-19 (optional)" 
					/>
				</Grid>
				<Grid item className='SignatureBox'>
					<span>Sign below <a onClick={clearSignature}>clear</a></span>
					<SignatureCanvas 
						ref={signatureRef}
						onEnd={() => ctx.setSignature(signatureRef.current!.toDataURL())}
						canvasProps={{
							width: 450,
							height: 150,
						}}
					/>
				</Grid>
				<Grid container direction="row" spacing={2} sx={{ my: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={() => {
							signatureRef.current?.clear()
							ctx.setSignature(undefined)
							props.goBack()
						}}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={props.goNext} disabled={!ctx.attest || !ctx.signature}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
