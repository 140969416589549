import React, { useContext, useEffect, useState } from "react"
import { Grid, LinearProgress } from "@mui/material"
import API from "../../api"
import { ReactComponent as IconLogin } from "../../assets/icons/Login.svg"
import { ReactComponent as IconMyChart } from "../../assets/icons/MyChart.svg"
import { ReactComponent as IconLab } from "../../assets/icons/Lab.svg"
import { ReactComponent as IconEmail } from "../../assets/icons/Email.svg"
import { ReactComponent as IconPhone } from "../../assets/icons/Phone.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { AppContext } from "../../context"
import { useHistory } from "react-router-dom"
import { FacilityUser } from "../../models"
import TabSelect from "../../components/TabSelect"
import { formatIncompletePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import { capitalizeFirstLetter, isValidEmail } from "../../util"
import { ILoginNavigate } from "."
import { LoginContext } from "./context"


export default function Login(props: ILoginNavigate) {
	const ctx = useContext(AppContext)
	const loginCtx = useContext(LoginContext)

	const [didNext, setDidNext] = useState<boolean>(false)
	const [phoneEmail, setPhoneEmail] = useState<string>('')
	const [email, setEmail] = useState<string>('')
	const [password, setPassword] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)

	const onLogin = () => {
		setDidNext(true)
		if (isPatient && phoneEmailType === undefined) {
			return
		}

		setIsError(false)
		setIsLoading(true)

		if (isPatient) {
			loginCtx.setLoginValue(phoneEmail)
			API.request2FA(phoneEmailType!, phoneEmail)
			.then(() => {
				loginCtx.setIsOtp(true)
			})
			.catch(error => alert("Failed to send login code, please check your phone / email and try again."))
			.finally(() => setIsLoading(false))
		} else {
			API.login<FacilityUser>(email, password)
			.then(({ user }) => {
				ctx.setUser(user)
				props.onLogin(user, "login")
			})
			.catch(error => {
				alert(error.response.data.message ?? "Login failed, please check your password and try again.")
			})
			.finally(() => setIsLoading(false))
		}
	}

	const onResetPassword = () => {
		setIsLoading(true)
		API.requestResetPassword(email)
		.then(() => {
			alert('A password reset link has been sent to the entered email if it belongs to an existing account.')
		})
		.catch(error => {
			if (error.response.status === 403) {
				alert("Failed to send password reset link - please wait at least 5 minutes since the last email to request another reset link.")
			} else {
				alert("Failed to send password reset link.")
			}
		})
		.finally(() => setIsLoading(false))
	}

	const [phoneEmailType, setPhoneEmailType] = useState<"phone" | "email" | undefined>()
	useEffect(() => {
		if (isValidEmail(phoneEmail)) {
			setPhoneEmailType("email")
		} else if (isValidPhoneNumber(phoneEmail, "BM")) {
			setPhoneEmailType("phone")
			let pn = parsePhoneNumber(phoneEmail, "BM")
			let stripped = phoneEmail.replace(' ', '')
			let text = ''
			if (stripped === '+1441' || stripped === '441') {
				text = ''
			} else if (pn.number.startsWith('+1441')) {
				let intl = pn.formatNational()
				text = intl.replace('(441) ', '')
			} else if (pn.country !== "BM") {
				text = pn.formatInternational()
			}
			setPhoneEmail(text)
		} else {
			setPhoneEmailType(undefined)
		}
	}, [phoneEmail])

	const [isPatient, setIsPatient] = useState<boolean>(true)

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Login</h1>
				<IconLogin />
			</div>
			{!props.allowProviders && <div className="Card-message">
				Don't have an account?
				<a 
					onClick={() => loginCtx.setMode("register")}
					style={{ marginLeft: 'auto', fontWeight: 700 }}
				>
					Register
				</a>
			</div>}
			{isLoading && <LinearProgress />}
			<Grid container sx={{ px: 3, pb: 3 }} className="Card-form">
				{props.allowProviders && <Grid container sx={{ mx: -0.5, pt: 2 }}>
					<TabSelect isActive={isPatient} onClick={() => setIsPatient(true)}>
						<IconMyChart />
						Patients
					</TabSelect>
					<TabSelect isActive={!isPatient} onClick={() => setIsPatient(false)}>
						<IconLab />
						Providers
					</TabSelect>
				</Grid>}
				<Grid container sx={{ py: 1 }}>
					{isPatient && <>
						<Input 
							label="Email or phone number"
							value={phoneEmail}
							onChange={e => setPhoneEmail(e.target.value)}
							helperText={didNext && phoneEmailType === undefined ? "Invalid email or phone number" : "Type '+' for international numbers"}
							error={isError || (didNext && phoneEmailType === undefined)}
							InputProps={{
								endAdornment: phoneEmailType !== undefined && (
									phoneEmailType === "email" 
										? <IconEmail width={24} height={24} fill="var(--color-text3)" />
										: <IconPhone width={24} height={24} fill="var(--color-text3)" />
								)
							}}
						/>
					</>}
					{!isPatient && <>
						<Input 
							label="Email"
							value={email}
							onChange={e => setEmail(e.target.value)}
						/>
						<a onClick={onResetPassword} style={{ marginLeft: 'auto', marginTop: 12 }}>
							Forgot password?
						</a>
						<Input 
							type="password"
							label="Password" 
							value={password}
							error={isError}
							onChange={e => setPassword(e.target.value)}
						/>
					</>}
				</Grid>
				<Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
					{props.goBack && <Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>}
					<Grid item xs>
						<ThemedButton onClick={onLogin}>LOGIN</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}