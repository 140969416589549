import React, { useContext, useEffect, useState } from "react"
import ThemedButton from '../../components/Button'
import { Grid, Box, Checkbox, LinearProgress, List, ListItem, ListItemButton, ListItemText, ListItemIcon } from '@mui/material'
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import { AppContext } from "../../context"
import { ReactComponent as IconDependentColor } from '../../assets/icons/DependentColor.svg'
import { ReactComponent as IconPlus } from '../../assets/icons/Plus.svg'
import { CovidTestReason, Patient } from "../../models"
import CreateDependentModal from "../../components/CreateDependentModal"
import API from "../../api"
import moment from "moment"


export default function Dependents(props: INavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(CovidTestsContext)

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [openModal, setOpenModal] = useState<boolean>(false)
	const [dependents, setDependents] = useState<Patient[]>()

	useEffect(() => {
		if (!dependents) {
			setIsLoading(true)
			API.getDependents()
			.then(({ dependents }) => setDependents(dependents))
			.catch(alert)
			.finally(() => setIsLoading(false))
		}
	}, [])

	
	return (<>
		<div className='Card'>
			<div className='Card-title'>
				<h1>Dependents</h1>
				<IconDependentColor />
			</div>
			{isLoading && <LinearProgress />}
			<div className='Card-message'>
				Book tests for multiple people at once
				<a onClick={() => setOpenModal(true)} className="row" style={{ marginLeft: 'auto' }}>
					<IconPlus style={{ marginRight: 4 }} fill="var(--color-theme)" />
					Add
				</a>
			</div>
			<Grid container sx={{ mt: 2 }}>
				<List sx={{ p: 0, width: '100%' }}>
					<ListItem key={appCtx.user?.id} sx={{  }}>
						<ListItemIcon>
							<Checkbox 
								tabIndex={-1}
								checked
								disabled
							/>
						</ListItemIcon>
						<ListItemText 
							primary={`${ctx.firstName} ${ctx.lastName}`}
							secondary="You"
						/>
					</ListItem>
					{dependents && dependents.map(d => 
						<ListItem key={d.id} sx={{ p: 0 }}>
							<ListItemButton role={undefined} onClick={() => {
								if (ctx.selectedDependentIds.includes(d.id)) {
									ctx.deselectDependent(d)
								} else {
									ctx.selectDependent(d)
								}
							}}>
								<ListItemIcon>
									<Checkbox 
										tabIndex={-1}
										checked={ctx.selectedDependentIds.includes(d.id)}
									/>
								</ListItemIcon>
								<ListItemText 
									primary={`${d.firstName} ${d.lastName}`}
									secondary={`${d.sex} ${ moment().diff(moment(d.dateOfBirth), 'years')} year old`}
								/>
							</ListItemButton>
						</ListItem>	
					)}
				</List>
				<Grid container direction="row" spacing={2} sx={{ mt: 1, px: 2, mb: 2 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={props.goNext} disabled={isLoading}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
		<CreateDependentModal 
			passportNumberRequired={ctx.testReason === CovidTestReason.travel}
			open={openModal} 
			onClose={updatedDependents => {
				setOpenModal(false)
				if (updatedDependents) {
					const newDependents = updatedDependents.filter(x => !dependents?.find(y => y.id === x.id))
					for (const newDependent of newDependents) {
						ctx.selectDependent(newDependent)
					}
					setDependents(updatedDependents)
				}
			}}
		/>
	</>)
}