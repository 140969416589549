import "./Header.css"
import React, { useContext, useState } from "react"
import Logo from "../assets/logo.png"
import { ReactComponent as IconVirus } from "../assets/icons/Virus.svg"
import { ReactComponent as IconSignedCert } from "../assets/icons/SignedCert.svg"
import { ReactComponent as IconLogout } from "../assets/icons/Logout.svg"
import { ReactComponent as IconUserLab } from "../assets/icons/UserLab.svg"
import { ReactComponent as IconMenu } from "../assets/icons/Menu.svg"
import { ReactComponent as IconClose } from "../assets/icons/Close.svg"
import { NavLink } from "react-router-dom"
import { AppContext } from "../context"
import clsx from "clsx"
import { LinearProgress } from "@mui/material"

const Header = () => {
	const ctx = useContext(AppContext)

	const [isCollapsed, setIsCollapsed] = useState<boolean>(true)

	const onNavigate = (e?: any) => {
		setIsCollapsed(true)
		window.scrollTo(0, 0)
	}

	return (
		<div className="Header">
			{!ctx.didBootstrap && <LinearProgress />}
			<div className="Header-content">
				<NavLink exact to="/" className="Header-logo" onClick={onNavigate}> 
					<img src={Logo} height={44} />
				</NavLink>
				<div className={clsx("Header-link-container", { "expand": !isCollapsed })}>
					<NavLink className="Header-link" exact to="/covid" onClick={onNavigate}>
						COVID Tests
						<IconVirus style={{ marginLeft: 8, width: 24, height: 24 }} />
					</NavLink>
					<NavLink className="Header-link" exact to="/certificate" onClick={onNavigate}>
						Certificate
						<IconSignedCert style={{ marginLeft: 8, width: 24, height: 24 }} />
					</NavLink>
					{ctx.didBootstrap && <div className="Header-right">
						{!ctx.user && <NavLink className="Header-link" exact to="/login" onClick={onNavigate}>
							Login
							<IconLogout style={{ marginLeft: 8, width: 24, height: 24 }} />
						</NavLink>}
						{ctx.user && ctx.user.type !== 'patient' && <NavLink className="Header-link" exact to="/facility" onClick={onNavigate}>
							Portal
							<IconUserLab style={{ marginLeft: 8, width: 24, height: 24 }} />
						</NavLink>}
						{ctx.user && ctx.user.type === 'patient' && <a className="Header-link" onClick={ctx.logout}>
							Logout
							<IconLogout style={{ marginLeft: 8, width: 24, height: 24 }} />
						</a>}
					</div>}
				</div>
				<a onClick={() => setIsCollapsed(!isCollapsed)} className="Menu-toggle">
					{isCollapsed ? <IconMenu className="Icon" /> : <IconClose className="Icon" />}
				</a>
			</div>
		</div>
	)
}

export default Header