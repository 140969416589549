import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import moment from 'moment'
import 'moment/locale/en-gb'
import 'mapbox-gl/dist/mapbox-gl.css'
import mapboxgl from 'mapbox-gl'

// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default

moment.locale('en-GB')

console.info('**** Developed by www.gdev.bm ****')
console.log({ environment: process.env.NODE_ENV })

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
)
