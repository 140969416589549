import './App.css'
import React, { useContext, useEffect } from 'react'
import { BrowserRouter, Switch, Route, useRouteMatch, useHistory, useLocation } from 'react-router-dom'
import Header from './components/Header'
import { createTheme, ThemeProvider, styled } from '@mui/material/styles'
import type {} from '@mui/lab/themeAugmentation'
import AppProvider, { AppContext } from './context'
import CovidTests from './pages/CovidTests'
import Booking from './pages/Booking'
import Verify from './pages/Verify'
import Certificate from './pages/Certificate'
import LoginAll from './pages/LoginAll'
import FacilityPortal from './pages/FacilityPortal'
import Footer from './components/Footer'
import Home from './pages/Home'
import ResetPassword from './pages/ResetPassword'

function AppContents() {	
	return (
		<div className='App'>
			<BrowserRouter>
				<AppProvider>
					<Header />
					<div className='App-content'>
						<Switch>
							{/*<Route exact path="/">
								<Home />
							</Route>*/}
							<Route exact path={["/covid", "/"]}>
								<CovidTests />
							</Route>
							<Route exact path="/booking/:confirmationNumber">
								<Booking />
							</Route>
							<Route exact path={[ "/verify", "/verify/:certificateToken" ]}>
								<Verify />
							</Route>
							<Route exact path="/certificate">
								<Certificate />
							</Route>
							<Route exact path="/login">
								<LoginAll />
							</Route>
							<Route exact path="/facility">
								<FacilityPortal />
							</Route>
							<Route exact path="/reset_password">
								<ResetPassword />
							</Route>
						</Switch>
					</div>
					<Footer />
				</AppProvider>
			</BrowserRouter>
		</div>
	)
}

export default function App () {
	const theme = createTheme({
		palette: {
			error: {
				main: '#E92D3B'
			},
			warning: {
				main: '#FFBE00'
			},
			success: {
				main: '#0F8D4B'
			}
		},
	})

	return (
		<ThemeProvider theme={theme}>
			<AppContents />
		</ThemeProvider>
	)
}
