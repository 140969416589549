import axios, { AxiosRequestConfig } from 'axios'
import createAuthRefreshInterceptor, { AxiosAuthRefreshRequestConfig } from 'axios-auth-refresh'
import { Facility, CovidTestBooking, Certificate, CovidTestResult, User, FacilityUser, CovidTestType, Booking, Patient, CreatePatientRequest, UpdatePatientRequest, GP, Availability } from './models'
import { State as CovidTestsState } from './pages/CovidTests/context'
import 'moment-timezone'

export const API_URL: string = process.env.REACT_APP_API_URL!

// defualt headers config
const defaultOptions = {
	baseURL: API_URL,
	headers: {
		'Content-Type': 'application/json',
	},
}

// create a new axios instance
export const instance = axios.create(defaultOptions)

instance.interceptors.request.use(
	(config) => {
		if (config.url === '/refresh') return config

		if (localStorage.getItem('USER_TOKEN')) {
			config.headers = {
				...config.headers,
				'Authorization': `Bearer ${localStorage.getItem('USER_TOKEN')}`
			}
		}
		return config
	},
	(error) => {
		return Promise.reject(error)
	}
)

createAuthRefreshInterceptor(instance, failedRequest => {
	const refreshToken = localStorage.getItem('REFRESH_TOKEN')
	if (!refreshToken) return Promise.reject()

	const config: AxiosRequestConfig = {
		headers: {
			'Authorization': `Bearer ${refreshToken}`
		}
	}
	return instance.post('/refresh', undefined, config).then(response => {
		localStorage.setItem('USER_TOKEN', response.data.token)
		localStorage.setItem('REFRESH_TOKEN', response.data.refreshToken)
		failedRequest.response.config.headers['Authorization'] = `Bearer ${response.data.token}`
		return Promise.resolve()
	}).catch((error) => {
		localStorage.removeItem('USER_TOKEN')
		localStorage.removeItem('REFRESH_TOKEN')
		window.location.reload()
		return Promise.reject()
	})
}, {
	pauseInstanceWhileRefreshing: true
})

type LoginResponse<T> = {
	user: T
	token: string
	refreshToken: string
}

export default class API {
	static async init() {
		return (await instance.get('/init')).data as {
			facilities: Facility[]
			gps: GP[]
		}
	}

	static async bookCovidTest(state: CovidTestsState) {
		let body = { 
			date: state.date?.tz('America/Halifax', true).format(),
			homeAddress: state.homeAddress,
			parish: state.parish,
			postcode: state.postcode,
			country: state.country,
			facility: state.facility,
			signature: state.signature,
			payNow: state.payNow,
			cardNumber: state.cardNumber,
			cardholderName: state.cardholderName,
			expiration: state.expiration,
			securityCode: state.securityCode,
			testReason: state.testReason,
			isTelehealth: state.isTelehealth,
			isCovidVaccinated: state.vaccinated,
			selectedDependentIds: state.selectedDependentIds,
		}
		return (await instance.post('/book_covid_test', body)).data as {
			booking: CovidTestBooking
		}
	}

	static async lookupCertificate(confirmationNumber: string, dateOfBirth: string) {
		const body = { confirmationNumber, dateOfBirth }
		return (await instance.post('/certificate', body)).data as {
			certificateToken: string
		}
	}
	
	static async verifyCertificate(certificateToken: string) {
		return (await instance.get(`/verify/${certificateToken}`)).data as {
			certificate: Certificate
		}
	}
	
	static async login<T>(email: string, password: string) {
		const body = { email, password }
		const config: AxiosAuthRefreshRequestConfig = { skipAuthRefresh: true }
		const response = (await instance.post('/login', body, config)).data as LoginResponse<T>
		localStorage.setItem('USER_TOKEN', response.token)
		localStorage.setItem('REFRESH_TOKEN', response.refreshToken)
		return response
	}
	
	static async getBooking(confirmationNumber: string) {
		return (await instance.get(`/booking/${confirmationNumber}`)).data as {
			booking: Booking
		}
	}
	
	static async getCovidTestBookingsOnDate(facilityId: number, date?: moment.Moment) {
		return (await instance.get(`/${facilityId}/covid_test_bookings${date ? `?date=${date.format('YYYY-MM-DD')}` : ''}`)).data as {
			bookings: CovidTestBooking[]
		}
	}
	
	static async submitTestResult(confirmationNumber: string, testResult: CovidTestResult, testType: CovidTestType) {
		const body = { confirmationNumber, testResult, testType }
		return (await instance.post('/test_result', body)).data
	}

	static async getFacilityUsers(facilityId: number) {
		return (await instance.get(`/${facilityId}/facility_user/all`)).data as {
			users: FacilityUser[]
		}
	}

	static async createFacilityUser(facilityId: number, name: string, email: string) {
		return (await instance.post(`/${facilityId}/facility_user`, { name, email })).data
	}

	static async updateFacilityUser(facilityId: number, id: number, name: string, email: string, signature: string) {
		return (await instance.put(`/${facilityId}/facility_user`, { id, name, email, signature })).data
	}

	static async deleteFacilityUser(facilityId: number, id: number) {
		return (await instance.delete(`/${facilityId}/facility_user/${id}`)).data
	}

	static async updateFacility(facility: Facility) {
		return (await instance.put('/facility', facility)).data
	}

	static async registerPatient(patient: CreatePatientRequest) {
		const response = (await instance.post('/register', patient)).data as LoginResponse<Patient>
		localStorage.setItem('USER_TOKEN', response.token)
		localStorage.setItem('REFRESH_TOKEN', response.refreshToken)
		return response
	}

	static async updatePatient(patient: UpdatePatientRequest) {
		return (await instance.put('/patient', patient)).data as {
			user: Patient
		}
	}

	static async request2FA(type: "phone" | "email", value: string) {
		return (await instance.post('/request_2fa', { [type]: value })).data
	}

	static async login2FA(value: string, code: string) {
		const config: AxiosAuthRefreshRequestConfig = { skipAuthRefresh: true }
		const response = (await instance.post('/login_2fa', { value, code }, config)).data as LoginResponse<Patient>
		localStorage.setItem('USER_TOKEN', response.token)
		localStorage.setItem('REFRESH_TOKEN', response.refreshToken)
		return response
	}

	static async getCertificateByFacilityUser(booking: CovidTestBooking) {
		return (await instance.get(`/certificate/facility_user/${booking.id}`)).data as {
			certificate: Certificate
		}
	}

	static async getUser() {
		return (await instance.get('/user')).data as {
			user: User
		}
	}

	static async requestResetPassword(email: string) {
		return (await instance.post('/facility_user/request_reset_password', { email })).data
	}

	static async resetPassword(resetToken: string, newPassword: string) {
		return (await instance.post(`/facility_user/reset_password`, { resetToken, newPassword })).data
	}

	static async getICalendarToken(facilityId: number) {
		return (await instance.post(`/facility/${facilityId}/generate_ical_token`)).data as {
			iCalToken: string
		}
	}

	static buildCalendarLink(facilityId: number, token?: string): string | undefined {
		if (token) {
			return `${API_URL}/facility/${facilityId}/ical/${token}.ics`
		}
	}

	static async getAvailability(facilityId: number, date: string) {
		return (await instance.get(`/availability/${facilityId}/${date}`)).data as Availability[]
	}

	static async getDependents() {
		return (await instance.get('/dependents')).data as {
			dependents: Patient[]
		}
	}

	static async createDependent(dependent: UpdatePatientRequest) { // use update since we don't have an email or phone
		return (await instance.post('/dependents', dependent)).data as {
			dependents: Patient[]
		}
	}

	static async cancelBooking(confirmationNumber: string, cancelledReason: string) {
		const body = { cancelledReason }
		return (await instance.post(`/cancel_booking/${confirmationNumber}`, body)).data
	}
}