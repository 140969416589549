import React, { useContext, useEffect, useState } from "react"
import { formatCurrency } from '../../util'
import { Grid, Box } from '@mui/material'
import ThemedButton from "../../components/Button"
import { ReactComponent as IconVirusColor } from '../../assets/icons/VirusColor.svg'
import { ReactComponent as IconSchoolColor } from '../../assets/icons/SchoolColor.svg'
import { ReactComponent as IconPartyColor } from '../../assets/icons/PartyColor.svg'
import { ReactComponent as IconOfficeColor } from '../../assets/icons/OfficeColor.svg'
import { ReactComponent as IconInfoColor } from '../../assets/icons/InfoColor.svg'
import { ReactComponent as IconTravelerRegistration } from "../../assets/icons/TravelerRegistration.svg"
import { ReactComponent as IconLocationColor } from "../../assets/icons/LocationColor.svg"
import { ReactComponent as IconVideoCall } from "../../assets/icons/VideoCall.svg"
import { ReactComponent as IconLocation } from "../../assets/icons/Location.svg"
import { CovidTestReason, FacilityType, getFacilityTypeIcon, formatAddress } from "../../models"
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import TabSelect from "../../components/TabSelect"
import { AppContext } from "../../context"

export default function Onboard(props: INavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(CovidTestsContext)

	// const [selectedLab, setSelectedLab] = useState<Facility>()

	const onNext = () => {
		if (appCtx.user?.type === "facility_user") {
			appCtx.logout()
		}
		props.goNext()
	}

	useEffect(() => {
		if (ctx.isTelehealth && ctx.facility && !ctx.facility.isTelehealth) {
			ctx.setFacility(undefined)
		}
	}, [ctx.isTelehealth])
	
	return (<>
		<div className='Card'>
			<div className='Card-title'>
				<h1>COVID-19 testing</h1>
				<IconVirusColor />
			</div>
			<div className='Card-message'>
				Schedule rapid antigen tests online and receive a verified negative test result certificate
			</div>
			<Grid container sx={{ p: 2 }}>
				<span className="subtitle" style={{ fontWeight: 600, marginLeft: 4 }}>Testing reason</span>
				<Grid container sx={{ pb: 2 }}>
					<TabSelect isActive={ctx.testReason === CovidTestReason.travel} onClick={() => ctx.setTestReason(CovidTestReason.travel)}>
						<IconTravelerRegistration />
						Travel
					</TabSelect>
					<TabSelect isActive={ctx.testReason === CovidTestReason.school} onClick={() => ctx.setTestReason(CovidTestReason.school)}>
						<IconSchoolColor />
						School
					</TabSelect>
					<TabSelect isActive={ctx.testReason === CovidTestReason.event} onClick={() => ctx.setTestReason(CovidTestReason.event)}>
						<IconPartyColor />
						Event
					</TabSelect>
					<TabSelect isActive={ctx.testReason === CovidTestReason.work} onClick={() => ctx.setTestReason(CovidTestReason.work)}>
						<IconOfficeColor />
						Work
					</TabSelect>
					<TabSelect isActive={ctx.testReason === CovidTestReason.publicHealth} onClick={() => ctx.setTestReason(CovidTestReason.publicHealth)}>
						<IconInfoColor />
						Know Your Status
					</TabSelect>
				</Grid>
				<span className="subtitle" style={{ fontWeight: 600, marginLeft: 4 }}>Test appointment</span>
				<Grid container sx={{ pb: 2 }}>
					<TabSelect isActive={!ctx.isTelehealth} onClick={() => ctx.setIsTelehealth(false)}>
						<IconLocationColor />
						In-Person
					</TabSelect>
					<TabSelect isActive={ctx.isTelehealth} onClick={() => ctx.setIsTelehealth(true)}>
						<IconVideoCall />
						Virtual
					</TabSelect>
				</Grid>
				<div className='Address' style={{ marginBottom: 16, width: '100%', backgroundColor: 'var(--color-foreground)', border: 'none' }}>
					<IconLocation fill="var(--color-text3)" width={44} />
					<div>
						{ctx.facility && <>
							<b>{ctx.facility.name}</b>
							<div className="text-2">{formatAddress(ctx.facility.address)}</div>
						</>}
						{!ctx.facility && <div className="text-2">
							Select a provider on the map to continue	
						</div>}
					</div>
				</div>
				<ThemedButton 
					onClick={onNext}
					style={{ width: '100%' }}
					disabled={!ctx.facility}
				>
					NEXT
				</ThemedButton>
			</Grid>
		</div>
		{/*<div className='Card'>
			<div className='LabCard'>
				<div className='LabCard-icon'>
					<IconTestTube 
						style={{ fill: selectedLab ? 'var(--color-theme)' : 'var(--color-border-highlight)'}} 
					/>
				</div>
				<div className='LabCard-content'>
					<span className='text-3' style={{ fontSize: selectedLab ? 14 : 16, fontWeight: 500 }}>Select a lab from the map</span>
					{selectedLab && <>
						<h3>{selectedLab.name}</h3>
						<span className='text-2'>
							<b style={{ color: 'var(--color-green)' }}>{formatCurrency(selectedLab.covidTestCost)}</b>
							<span className='text-border' style={{ margin: '0 8px' }}>&bull;</span>
							{() => {
								let s = selectedLab.schedule.find(x => x.day === moment().weekday())
								if (s) {
									return `${s.opensAt} – ${s.closesAt}`
								}
							}}
						</span>
					</>}
				</div>
			</div>
			{selectedLab && <Grid container sx={{ px: 2, py: 1 }}>
				<Grid item container spacing={1} sx={{ p: 1}}>
					<div 
						className={`TestOption ${!ctx.isTelehealth ? 'active ': ''}`}
						onClick={() => ctx.setIsTelehealth(false)}
					>
						<IconLocation />
						In-person
					</div>
					<div 
						className={`TestOption ${ctx.isTelehealth ? 'active ': ''}`}
						onClick={() => ctx.setIsTelehealth(true)}
					>
						<IconVideo />
						Video call
					</div>
				</Grid>
				<ThemedButton 
					onClick={props.goNext}
					disabled={!selectedLab}
				>
					NEXT
				</ThemedButton>
			</Grid>}
		</div>*/}
		<div className='Card'>
		</div>
	</>)
}