import React, { createRef, useContext, useEffect, useRef, useState } from "react"
import { alertTitleClasses, Grid, LinearProgress } from "@mui/material"
import API from "../../api"
import { ReactComponent as IconOTP } from "../../assets/icons/OTP.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { AppContext } from "../../context"
import { useHistory } from "react-router-dom"
import { FacilityUser } from "../../models"
import TabSelect from "../../components/TabSelect"
import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js"
import { capitalizeFirstLetter, isValidEmail } from "../../util"
import { LoginContext } from "./context"
import { ILoginNavigate } from "."
import OtpInput from 'react-otp-input'


export default function OTP(props: ILoginNavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(LoginContext)
	const history = useHistory()

	const [formattedPhone, setFormattedPhone] = useState<string>()
	const [code, setCode] = useState<string>('')
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)
	const [isPhone, setIsPhone] = useState<boolean>()
	const [isResending, setIsResending] = useState<boolean>(false)

	useEffect(() => {
		try {
			let pn = parsePhoneNumber(ctx.loginValue!, "BM")
			let intl = pn.formatInternational()
			setFormattedPhone(intl)
			setIsPhone(true)
		} catch {
			setFormattedPhone(ctx.loginValue)
			setIsPhone(false)
		}
	}, [])

	const onSubmit = () => {
		setIsError(false)
		setIsLoading(true)

		API.login2FA(ctx.loginValue!, code)
		.then(({ user }) => {
			appCtx.setUser(user)
			props.onLogin(user, "login")
			ctx.setIsOtp(false)
			ctx.setMode("register")
		})
		.catch(error => {
			if (error.response.data.status === 403) {
				alert(error.response.data.message)
			}
			setIsError(true)
			setCode("")
		})
		.finally(() => setIsLoading(false))
	}

	const resendCode = () => {
		setIsLoading(true)
		setIsResending(true)
		API.request2FA(isPhone ? "phone" : "email", ctx.loginValue!)
		.then(() => {
			
		})
		.catch(error => alert("Failed to resend code, please wait at least 60 seconds since the last resend."))
		.finally(() => {
			setIsLoading(false)
			setIsResending(false)
		})
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Verification Code</h1>
				<IconOTP />
			</div>
			{isLoading && <LinearProgress />}
			<div className="Card-message">
				<div>
					A 4-digit authentication code has been sent to {formattedPhone}
					<br />
					<a 
						onClick={isResending ? undefined : resendCode} 
						style={{ color: isResending ? 'var(--color-text3)' : 'var(--color-theme)' }}
					>
						Resend code
					</a>
				</div>
			</div>
			<Grid container sx={{ px: 3, pb: 3 }}>
				<Grid item sx={{ py: 2, mx: 'auto' }}>
					<OtpInput 
						shouldAutoFocus
						value={code}
						onChange={(value: string) => {
							setCode(value)
							setIsError(false)
						}}
						numInputs={4}
						hasErrored={isError}
						isInputNum={true}
						inputStyle={{
							border: 'none',
							borderRadius: 0,
							borderBottomWidth: 1.5,
							borderBottomStyle: 'solid',
							borderBottomColor: 'var(--color-border)',
							padding: '16px 24px',
							outline: 'none',
							marginLeft: 12,
							marginRight: 12,
							transition: '0.2s',
							color: 'var(--color-text)',
							fontWeight: 400,
							fontSize: 18
						}}
						errorStyle={{
							borderBottomColor: 'var(--color-red)',
							borderBottomWidth: 2
						}}
						focusStyle={{
							borderBottomColor: 'var(--color-theme)',
							borderBottomWidth: 2
						}}
					/>
				</Grid>

				<Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={() => ctx.setIsOtp(false)}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={onSubmit} disabled={code.length !== 4}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}