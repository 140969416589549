import React from "react"
import { alpha, styled } from "@mui/material/styles"
import { Button, ButtonProps } from "@mui/material"

const ThemedButton = (props: ButtonProps) => (
	<Button 
		variant={props.variant ?? "contained"} 
		sx={{ 
			...props.sx,
			width: '100%', 
			fontWeight: 'bold', 
			backgroundColor: props.variant !== undefined && props.variant !== "text" ? 'var(--color-theme)' : undefined,
			'&:hover': {
				backgroundColor: props.variant !== undefined && props.variant !== "text" ? 'var(--color-theme-highlight)' : undefined
			},
			'&:disabled': {
				backgroundColor: 'var(--color-foreground)',
				color: 'var(--color-text3)'
			}
		}}
		{...props}
	>
		{props.children}
	</Button>
)

export default ThemedButton