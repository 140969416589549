import React from "react"
import { useState } from "react"
import { FacilityUser, FacilityUserAssociation } from "../../models"

type State = {
	user?: FacilityUser
	setUser: (_: FacilityUser) => void
	facility?: FacilityUserAssociation
	setFacility: (_: FacilityUserAssociation) => void
}

export const FacilityPortalContext = React.createContext<State>({
	user: undefined,
	setUser: () => {},
	facility: undefined,
	setFacility: () => {}
})

export default function FacilityPortalProvider(props: React.PropsWithChildren<{}>) {
	const [user, setUser] = useState<FacilityUser>()
	const [facility, setFacility] = useState<FacilityUserAssociation>()
	
	return (<div className="FacilityPortal">
		<FacilityPortalContext.Provider value={{
			user, setUser,
			facility, setFacility
		}}>
			{props.children}
		</FacilityPortalContext.Provider>
	</div>)
}