import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import Map, { useMap, Marker, Popup } from 'react-map-gl'
import { Facility, FacilityType, formatAddress, getFacilityTypeIcon } from "../../models"
import { CovidTestsContext } from "../../pages/CovidTests/context"
import { formatCurrency } from "../../util"
import Filters, { Filter } from "./Filters"
import { ReactComponent as IconClose } from "../../assets/icons/Close.svg"
import './index.css'
import moment from 'moment'
import { AppContext } from "../../context"

type Props = {
	isLocked: boolean

	facilities: Facility[]
	filters?: Filter[]

	activeFacility?: Facility
	setActiveFacility: (_?: Facility) => void

	activeFilter?: Filter
	setActiveFilter?: (_?: Filter) => void
}

const FacilityPopup = (props: Props) => {
	if (!props.activeFacility) return <span />

	const schedule = props.activeFacility.schedule.find(x => x.day === moment().weekday())
	return (<>
		{!props.isLocked && <a className="MapPopup-close" onClick={() => props.setActiveFacility(undefined)}>
			<IconClose />
		</a>}
		<b>{props.activeFacility.name}</b>
		<div className="text-3">{props.activeFacility.address.address}, {props.activeFacility.address.parish} {props.activeFacility.address.postcode}</div>
		<div className="row" style={{ marginTop: 12 }}>
			<div className="Tag green" style={{ marginRight: 8 }}>{formatCurrency(props.activeFacility.covidTestCost)}</div>
			<div className={`Tag ${schedule?.isOpen ? '' : 'red'}`}>{schedule?.isOpen ? 'Open today' : 'Closed today'}</div>
		</div>
	</>)
}

const MapInner = (props: Props & { isMobile: boolean }) => {
	const ctx = useContext(CovidTestsContext)
	const { current: map } = useMap()

	const flyToActiveFacility = () => {
		if (ctx.facility?.address.longitude && ctx.facility?.address.latitude) {
			map?.flyTo({
				center: [
					ctx.facility.address.longitude + (props.isMobile ? 0 : 0.005),
					ctx.facility.address.latitude
				],
				zoom: 15.5,
				speed: 0.8
			})
		}
	}

	useEffect(() => {
		flyToActiveFacility()
	}, [ctx.facility])

	useEffect(() => {
		if (props.isLocked) {
			flyToActiveFacility()
		}
	}, [props.isLocked])

	return <span />
}

export default function MapView(props: React.PropsWithChildren<Props>) {
	const isMobile: boolean = window.matchMedia('(max-width: 900px)').matches

	const [viewState, setViewState] = useState({
		longitude: -64.75, // X
		latitude: 32.28, // Y
		zoom: 12
	})

	const markers = useMemo(() => props.facilities.map(facility => (
		<Marker
			key={facility.id}
			longitude={facility.address.longitude}
			latitude={facility.address.latitude}
			style={{ width: 32, height: 32, borderRadius: 16 }}
			onClick={props.isLocked ? undefined : () => props.setActiveFacility(facility)}
		>
			<div className={`MapMarker ${props.activeFacility?.id === facility.id ? 'active' : ''}`}>
				<div className="MapMarker-bubble">
					{getFacilityTypeIcon(FacilityType[facility.facilityType as keyof typeof FacilityType], props.activeFacility?.id === facility.id)}
				</div>
				<span>
					{facility.name.slice(0, 12)}... 
					<b style={{ color: 'var(--color-green)' }}>{formatCurrency(facility.covidTestCost)}</b>
				</span>
			</div>
		</Marker>
	)), [props.facilities, props.activeFacility])

	return (
		<div className={`Map ${props.isLocked ? 'locked' : ''}`}>
			{props.filters && <Filters filters={props.filters} activeFilter={props.activeFilter} setActiveFilter={props.setActiveFilter} />}
			<Map reuseMaps
				{...viewState}
				onMove={e => setViewState(e.viewState)}
				style={{
					position: 'fixed',
					minHeight: '100vh',
					width: '100%',
					top: 0
				}}
				minZoom={11}
				dragPan={!props.isLocked}
				touchZoomRotate={!props.isLocked}
				scrollZoom={!props.isLocked}
				doubleClickZoom={!props.isLocked}
				dragRotate={false}
				pitchWithRotate={false}
				mapboxAccessToken={process.env.REACT_APP_MAPBOX_KEY!}
				mapStyle='mapbox://styles/covidexposer/cl3g7jaww003s15o4yxlr69we'
			>
				<MapInner {...props} isMobile={isMobile} />
				{props.activeFacility && <Popup
					anchor="bottom"
					longitude={props.activeFacility.address.longitude!}
					latitude={props.activeFacility.address.latitude!}
					closeOnClick={false}
					closeButton={false}
					className="MapPopup"
					offset={[1, -22]}
				>
					{props.activeFacility && <FacilityPopup {...props} />}
				</Popup>}
				{markers}
			</Map>
			<div className="Map-cards">
				{props.children}
			</div>
		</div>
	)
}