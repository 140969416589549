import { Alert, MenuItem, Snackbar } from '@mui/material'
import moment from 'moment'
import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import API from '../../api'
import { AppContext } from '../../context'
import { FacilityUser } from '../../models'
import './index.css'
import ThemedButton from '../../components/Button'
import ThemedSelect from '../../components/Select'
import Users from './Users'
import { ReactComponent as IconLabCoat } from '../../assets/icons/LabCoat.svg'
import { ReactComponent as IconTest } from '../../assets/icons/Test.svg'
import { ReactComponent as IconSettings } from '../../assets/icons/Settings.svg'
import { ReactComponent as IconSave } from '../../assets/icons/Save.svg'
import { ReactComponent as IconLogout } from '../../assets/icons/Logout.svg'
import Settings from './Settings'
import CovidTestBookings from './CovidTestBookings'
import FacilityPortalProvider, { FacilityPortalContext } from './context'
import SelectFacility from './SelectFacility'

const FacilityPortal = () => {
	const appCtx = useContext(AppContext)
	const ctx = useContext(FacilityPortalContext)
	const history = useHistory()

	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [isSaving, setIsSaving] = useState<boolean>(false)
	const [activeDate, setActiveDate] = useState<moment.Moment | undefined>(moment())
	const [didSave, setDidSave] = useState<boolean>(false)

	type Tab = "reservations" | "users" | "settings"
	const [activeTab, setActiveTab] = useState<Tab>("reservations")

	useEffect(() => {
		console.log("ONCHANGE", appCtx.didBootstrap, appCtx.user?.type)
		if (appCtx.didBootstrap) {
			if (appCtx.user?.type === "facility_user") {
				const fu = appCtx.user as FacilityUser
				if (fu.facilities.length === 1) {
					ctx.setFacility(fu.facilities[0])
				}
				ctx.setUser(fu)
			} else {
				history.push('/login')
			}
		}
	}, [appCtx.user, appCtx.didBootstrap])

	const saveChanges = () => {
		let shouldSave = true
		ctx.facility!.schedule.forEach(s => {
			const name = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Friday"][s.day]
			if (s.isOpen && (s.opensAt === null || s.closesAt === null)) {
				shouldSave = false
				alert(`${name} marked as "Is Open": requires opening and closing times`)
				return
			} else if (moment(s.opensAt, 'h:mm a') >= moment(s.closesAt, 'h:mm a')) {
				shouldSave = false
				alert(`${name} opening and closing times are in the incorrect order`)
				return
			} else if (moment(s.opensAtVirtual, 'h:mm a') >= moment(s.closesAtVirtual, 'h:mm a')) {
				shouldSave = false
				alert(`${name} virtual opening and closing times are in the incorrect order`)
				return
			}
		})

		if (shouldSave) {
			setIsSaving(true)
			API.updateFacility(ctx.facility!)
			.then(() => setDidSave(true))
			.catch(error => alert(error.response?.data?.message ?? 'Failed to save settings.'))
			.finally(() => setIsSaving(false))
		}
	}

	return (<>
		{ctx.user && <>
		<h1>Welcome, {ctx.user?.name}</h1>
		<span className='text-3' style={{ marginTop: 4 }}>{ctx.facility?.name}</span>
			{!ctx.facility && <SelectFacility />}
			{ctx.facility && <>
			<div className='FacilityPortal-tabs'>
				<a className={`FacilityPortal-tab ${activeTab === "reservations" ? "active" : ""}`} onClick={() => setActiveTab("reservations")}>
					Covid Test Bookings
					<IconTest />
				</a>
				<a className={`FacilityPortal-tab ${activeTab === "users" ? "active" : ""}`} onClick={() => setActiveTab("users")}>
					Manage Users
					<IconLabCoat />
				</a>
				{ctx.facility?.isAdmin && <a className={`FacilityPortal-tab ${activeTab === "settings" ? "active" : ""}`} onClick={() => setActiveTab("settings")}>
					Settings
					<IconSettings />
				</a>}
				<a className='FacilityPortal-tab' onClick={appCtx.logout}>
					Logout
					<IconLogout />
				</a>
				{activeTab === "reservations" && <div style={{ marginLeft: 'auto' }}>
					<ThemedSelect 
						placeholder="Select date" 
						sx={{ width: 150 }}
						value={activeDate ? activeDate.format('MMDDYYYY') : "All time"}
						onChange={e => e.target.value === "All time" ? setActiveDate(undefined) : setActiveDate(moment(e.target.value as string, 'MMDDYYYY'))}
						variant="standard"
					>
						<MenuItem value="All time" key="all">All time</MenuItem>
						{[...Array(14)].map((_, i) => {
							const x = moment().add(i-1, 'days')
							return <MenuItem value={x.format('MMDDYYYY')} key={i}>{x.format('MMMM D')}</MenuItem>
						})}
					</ThemedSelect>
				</div>}
				{activeTab === "settings" && <div style={{ marginLeft: 'auto' }}>
					<ThemedButton 
						onClick={saveChanges}
						disabled={isSaving}
					>
						<IconSave fill="white" width={24} height={24} style={{ marginRight: 8 }} /> Save
					</ThemedButton>
				</div>}
			</div>
			{activeTab === "reservations" && <CovidTestBookings activeDate={activeDate} />}
			{activeTab === "users" && <Users />}
			{activeTab === "settings" && ctx.facility?.isAdmin && <Settings />}
			<Snackbar 
				open={didSave}
				autoHideDuration={3000}
				onClose={() => setDidSave(false)}
				
			>
				<Alert severity='success' variant="filled" sx={{ width: '100%' }}>
					Settings saved
				</Alert>
			</Snackbar>
			</>}
		</>}
	</>)
}

export default function FacilityPortalHOC() {
	return (
		<FacilityPortalProvider>
			<FacilityPortal />
		</FacilityPortalProvider>
	)
}