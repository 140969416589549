import React, { useContext, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Grid, LinearProgress } from "@mui/material"
import API from "../api"
import Input from "../components/Input"
import ThemedButton from "../components/Button"
import { ReactComponent as IconResetPassword } from "../assets/icons/ResetPassword.svg"
import { useQuery } from "../hooks"


export default function ResetPassword() {
	const history = useHistory()
	const query = useQuery()
	const resetToken = query.get('token')

	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [newPassword, setNewPassword] = useState<string>()
	const [repeatPassword, setRepeatPassword] = useState<string>()
	const [didNext, setDidNext] = useState<boolean>(false)

	if (!resetToken) {
		history.push('/login')
	}

	const onSubmit = () => {
		setDidNext(true)
		if (!newPassword || repeatPassword !== newPassword) {
			return
		}

		setIsLoading(true)
		API.resetPassword(resetToken!, newPassword)
		.then(() => {
			alert("Your password has been set. Please login using your new credentials.")
			history.push('/login')
		})
		.catch(error => alert("Failed to reset password, please try again."))
		.finally(() => setIsLoading(false))
	}

	return (<div className='Slides'>
		<div className="Card">
			<div className="Card-title" style={{ padding: 24, paddingBottom: 8 }}>
				<h1>Reset Password</h1>
				<IconResetPassword />
			</div>
			{isLoading && <LinearProgress />}
			<Grid container sx={{ px: 3, pb: 3 }} className="Card-form">
				<Input 
					type="password"
					label="New Password"
					value={newPassword}
					onChange={e => setNewPassword(e.target.value)}
					error={didNext && !newPassword}
					autoComplete="off"
				/>
				<Input 
					type="password"
					label="Repeat Password"
					value={repeatPassword}
					onChange={e => setRepeatPassword(e.target.value)}
					error={didNext && newPassword !== repeatPassword}
					autoComplete="off"
				/>
				<ThemedButton onClick={onSubmit} style={{ marginTop: 24 }}>SUBMIT</ThemedButton>
			</Grid>
		</div>
	</div>)
}
