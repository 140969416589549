import { Grid, Box, BoxProps } from "@mui/material"

type TabSelectProps = {
	isActive?: boolean
	onClick: () => void
}
const TabSelect = (props: React.PropsWithChildren<TabSelectProps & BoxProps>) => (
	<Grid item sx={{ p: 0.5 }} onClick={props.onClick}>
		<Box sx={{
			border: `1.5px solid var(--color-${props.isActive ? 'theme' : 'border'})`,
			backgroundColor: props.isActive ? 'var(--color-theme-background)' : 'transparent',
			borderRadius: 2,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			color: `var(--color-${props.isActive ? 'theme' : 'text2'})`,
			fontSize: 16,
			fontWeight: 600,
			padding: 1,
			pl: 1.5,
			pr: 2,
			transition: '0.2s',
			'& svg': {
				width: 28,
				height: 28,
				marginRight: 1
			},
			'&:hover': props.isActive ? {} : {
				cursor: 'pointer',
				filter: 'brightness(0.95)',
				backgroundColor: 'var(--color-foreground)'
			},
			...props.sx
		}}>
			{props.children}
		</Box>
	</Grid>
)

export default TabSelect