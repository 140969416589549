import React, { useContext, useEffect, useState, useMemo } from "react"
import { Grid, MenuItem } from "@mui/material"
import { ReactComponent as IconSchedule } from "../../assets/icons/Schedule.svg"
import ThemedButton from "../../components/Button"
import ThemedSelect from "../../components/Select"
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import { FacilitySchedule, formatAddress } from "../../models"
import SchedulePicker from '../../components/SchedulePicker'
import { formatCurrency } from "../../util"
import moment from "moment"
import { DEFAULT_COVID_TEST_COST } from "../../const"
import API from "../../api"
import { AppContext } from "../../context"


export default function Schedule(props: INavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(CovidTestsContext)

	const facilities = appCtx.facilities.filter(x => x.isCovidLive && (ctx.isTelehealth ? x.isTelehealth : true))

	const [didNext, setDidNext] = useState<boolean>(false)

	const onNext = () => {
		setDidNext(true)
		const outOfRange: boolean = false // !ctx.date || (ctx.date)
		if (!ctx.facility || !ctx.date || !ctx.date.isValid() || outOfRange) {
			return
		} else {
			props.goNext()
		}
	}

	const [schedule, setSchedule] = useState<FacilitySchedule>()

	type ScheduleRow = {
		time: moment.Moment
		count: number
	}
	const [times, setTimes] = useState<ScheduleRow[]>()
	const [isLoading, setIsLoading] = useState<boolean>(false)

	const isDateValid: boolean = useMemo(() => {
		const s = ctx.facility?.schedule.find(s => s.day === ctx.date?.weekday())
		if (!s || !ctx.date) return false
		const start = moment(ctx.isTelehealth ? s.opensAtVirtual : s.opensAt, 'h:mm a').tz('America/Halifax', true)
		const end = moment(ctx.isTelehealth ? s.closesAtVirtual : s.closesAt, 'h:mm a').tz('America/Halifax', true)
		const d = moment(ctx.date.format('h:mm a'), 'h:mm a').tz('America/Halifax', true)
		return ctx.date.isValid() && s.isOpen && start <= d && end >= d 
	}, [ctx.facility, ctx.date])

	const roundTime15 = (t: moment.Moment) => moment(t).add(15 - (t.minute() % 15), 'minutes')

	useEffect(() => {
		(async () => {
			if (!ctx.facility || !ctx.date) return;

			let s = ctx.facility.schedule.find(s => s.day === ctx.date?.weekday())
			setSchedule(s)
			setIsLoading(true)

			if (s) {
				const availability = await API.getAvailability(ctx.facility!.id, ctx.date.format('YYYY-MM-DD'))
				setIsLoading(false)

				let start = moment(ctx.isTelehealth ? s.opensAtVirtual : s.opensAt, 'h:mm a')
				const end = moment(ctx.isTelehealth ? s.closesAtVirtual : s.closesAt, 'h:mm a')

				if (ctx.date.isSame(moment().tz('America/Halifax'), 'day')) {
					start = moment.max(start, roundTime15(moment()))
				}

				const subdivisions = end.diff(start, 'minutes') / 15
				const ts: ScheduleRow[] = Array.from({ length: subdivisions }).map((_, i) => {
					const slot = moment(start).add(i * 15, 'minutes')
					const a = availability.find(x => x.time === slot.format('HH:mm'))					
					return { time: slot, count: a?.count ?? 0 }
				})
				setTimes(ts)
				/*
				let max = roundTime15(moment(ctx.date.format('YYYY-MM-DD') + ' ' + end.format('h:mm a'), 'YYYY-MM-DD h:mm a').subtract(30, 'minutes'))
				let min = roundTime15(moment(ctx.date.format('YYYY-MM-DD') + ' ' + start.format('h:mm a'), 'YYYY-MM-DD h:mm a'))
				
				if (max < ctx.date) {
					ctx.setDate(max)
				} else if (min > ctx.date) {
					ctx.setDate(min)
				}*/
			} else {
				setTimes(undefined)
			}
		})()
	}, [ctx.facility, ctx.date, ctx.isTelehealth])

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Schedule Test</h1>
				<IconSchedule />
			</div>
			<div className="Card-message" style={{ flexDirection: "column", alignItems: "flex-start" }}>
				US travel regulations require a test conducted no more than 1 calendar day before departure.
				<a target="_blank" href="https://www.gov.bm/outbound-travel-guidance" style={{ marginTop: 4 }}>View all travel regulations</a>
			</div>
			<Grid container direction="column" className="Card-form" sx={{ px: 4, pt: 0, pb: 2 }}>
				<Grid item sx={{ width: '100%' }}>
					<ThemedSelect 
						placeholder="Select testing facility"
						value={ctx.facility?.id}
						onChange={e => {
							ctx.setFacility(facilities.find(f => f.id === e.target.value))
							ctx.setDate(null)
						}}
						error={didNext && !ctx.facility}
					>
						{facilities.map(f => 
							<MenuItem value={f.id} key={f.id}>
								<div>
									{f.name}
									<div style={{ color: 'var(--color-text3)', fontSize: 12 }}>
										<b style={{ color: 'var(--color-green)', marginRight: 6 }}>{formatCurrency(f.covidTestCost ?? DEFAULT_COVID_TEST_COST)}</b>
										{formatAddress(f.address)}
									</div>
								</div>
							</MenuItem>
						)}
					</ThemedSelect>
				</Grid>
				{ctx.facility && <Grid item sx={{ mt: 0, position: 'relative' }}>
					<SchedulePicker
						facility={ctx.facility}
						value={ctx.date}
						onChange={(value) => ctx.setDate(value)}
						error={didNext && (!ctx.date || !ctx.date.isValid())}
					/>
				</Grid>}
				{ctx.facility && schedule && <Grid item sx={{ mb: 1 }}>
					<ThemedSelect
						placeholder="Booking time"
						value={ctx.date?.format('h:mm a')}
						onChange={e => {
							const date = ctx.date?.format('YYYY-MM-DD')
							let t = e.target.value as string
							ctx.setDate(moment(date + ' ' + t, 'YYYY-MM-DD h:mm a'))
						}}
						error={!ctx.date && didNext}
					>
						{times?.map(t => {
							const disabled = ctx.facility!.maxBookings 
								? (t.count + ctx.numBookings > ctx.facility!.maxBookings) 
								: false

							return <MenuItem 
								value={t.time.format('h:mm a')}
								disabled={disabled}
							>
								{t.time.format('h:mm a')}{disabled ? ' (full)' : ''}
							</MenuItem>
						})}
					</ThemedSelect>
				</Grid>}
				<Grid container direction="row" spacing={2} sx={{ mt: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={onNext} disabled={!isDateValid || isLoading}>NEXT</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
