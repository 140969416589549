import { CircularProgress, Fade, Slide } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import VerifyProvider, { VerifyContext } from './context'
import { TransitionGroup } from 'react-transition-group'
import { useParams } from 'react-router-dom'
import Lookup from './Lookup'
import API from '../../api'
import Results from './Results'
import Processing from './Processing'

function Verify() {
	const { certificateToken } = useParams<{ certificateToken?: string }>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const ctx = useContext(VerifyContext)

	useEffect(() => {
		loadCertificate()
	}, [certificateToken])
	
	const loadCertificate = async () => {
		if (!certificateToken) return

		setIsLoading(true)
		try {
			const { certificate } = await API.verifyCertificate(certificateToken)
			ctx.setCertificate(certificate)
		} catch {
			console.error('cannot load')
		} finally {
			setIsLoading(false)
		}
	}
	
	const Card = ({ show, initial, children }: React.PropsWithChildren<{ show: boolean, initial?: boolean }>) => {
		const renderSlide = (
			<Fade in={show} timeout={400} className={show ? 'visible' : undefined}>
				<div>
					<Slide 
						direction="left"
						in={show}
						timeout={200}
						mountOnEnter
					>
						<div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
							{children}
						</div>
					</Slide>
				</div>
			</Fade>
		)
		return (initial ?
			<TransitionGroup>
				{renderSlide}
			</TransitionGroup>
			:
			<>{renderSlide}</>
		)
	}

	if (isLoading) {
		return <CircularProgress />
	}

	return (<div className='Slides'>
		<Card show={!ctx.certificate}>
			<Lookup /> 
		</Card>
		<Card show={!!ctx.certificate && !!ctx.certificate.testResult}>
			<Results /> 
		</Card>
		<Card show={!!ctx.certificate && !ctx.certificate.testResult}>
			<Processing /> 
		</Card>
	</div>)
}

export default function VerifyHOC() {
	return (<div className="Verify">
		<VerifyProvider>
			<Verify />
		</VerifyProvider>
	</div>)
}