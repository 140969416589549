export default function Contact() {
	return (<div>
		<img alt="bhec" src={require('../assets/images/bhec.png')} style={{ maxWidth: '60%' }} />
		<p style={{ marginBottom: 8, marginTop: 8 }}>
			<b>Do you have any questions or feedback about HealthIQ? We would love to hear from you.</b>
		</p>
		<p style={{ marginBottom: 8 }}>Phone: +1 (441) 292-6420</p>
		<p style={{ marginBottom: 8 }}>Fax: +1 (441) 292-8067</p>
		<p>
			Email: <a href="mailto:healthcouncil@bhec.bm">healthcouncil@bhec.bm</a>
		</p>
	</div>)
}