import React, { useContext, useState } from "react"
import { Alert, LinearProgress } from "@mui/material"
import { DatePicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterMoment'
import API from "../api"
import { ReactComponent as IconCertificateColor } from "../assets/icons/CertificateColor.svg"
import ThemedButton from "../components/Button"
import Input from "../components/Input"
import { useHistory } from "react-router-dom"
import moment from "moment"


export default function Download() {
	const [confirmationNumber, setConfirmationNumber] = useState<string>('')
	const [dateOfBirth, setDateOfBirth] = useState<moment.Moment | null>(null)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [isError, setIsError] = useState<boolean>(false)

	const history = useHistory()

	const onLookup = async () => {
		if (!dateOfBirth) return

		setIsLoading(true)
		try {
			setIsError(false)
			const { certificateToken } = await API.lookupCertificate(confirmationNumber, dateOfBirth.format())
			history.push(`/verify/${certificateToken}`)
		} catch {
			setIsError(true)
		} finally {
			setIsLoading(false)
		}
	}

	return (<div className="Slides">
		<div className="Card">
			<div className="Card-title">
				<h1>Get Certificate</h1>
				<IconCertificateColor />
			</div>
			{isLoading && <LinearProgress />}
			<div className="Card-message">
				If you've already taken a COVID test, you can download your negative result certificate here.
			</div>
			<div className="Card-form" style={{ padding: 32, paddingTop: 0 }}>
				{isError && <Alert severity="error" sx={{ mt: 2 }}>Certificate not found</Alert>}
				<Input 
					label="Order confirmation number" 
					value={confirmationNumber}
					onChange={e => setConfirmationNumber(e.target.value.toUpperCase())}
				/>
				<LocalizationProvider dateAdapter={DateAdapter}>
					<DatePicker
						mask="__/__/____"
						label="Date of birth"
						value={dateOfBirth}
						onChange={(value) => setDateOfBirth(value)}
						renderInput={(params) => <Input {...params} />}
					/>
				</LocalizationProvider>
				<ThemedButton 
					style={{ marginTop: 24 }} 
					onClick={onLookup}
					disabled={isLoading}
				>
					NEXT
				</ThemedButton>
			</div>
		</div>
	</div>)
}
