import React from "react"
import { useState } from "react"

export type LoginViewMode = "login" | "register"

type State = {
	mode: LoginViewMode
	setMode: (_: LoginViewMode) => void
	isOtp: boolean
	setIsOtp: (_: boolean) => void
	loginValue?: string
	setLoginValue: (_: string) => void
}

export const LoginContext = React.createContext<State>({
	mode: "register",
	setMode: () => {},
	isOtp: false,
	setIsOtp: () => {},
	loginValue: undefined,
	setLoginValue: () => {}
})

type Props = {
	startMode: LoginViewMode
}

export default function LoginProvider(props: React.PropsWithChildren<Props>) {
	const [mode, setMode] = useState<LoginViewMode>(props.startMode)
	const [isOtp, setIsOtp] = useState<boolean>(false)
	const [loginValue, setLoginValue] = useState<string>()

	return (<div className="">
		<LoginContext.Provider value={{
			mode, setMode,
			isOtp, setIsOtp,
			loginValue, setLoginValue
		}}>
			{props.children}
		</LoginContext.Provider>
	</div>)
}