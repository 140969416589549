import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Modal } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import React, { useContext, useEffect, useState } from "react"
import API from "../../api"
import ThemedButton from "../../components/Button"
import IconButton from "../../components/IconButton"
import NoRows from "../../components/NoRows"
import { FacilityUser } from "../../models"
import { ReactComponent as IconDelete } from "../../assets/icons/Delete.svg"
import { ReactComponent as IconPlus } from "../../assets/icons/Plus.svg"
import { ReactComponent as IconEdit } from "../../assets/icons/Edit.svg"
import CreateUserModal from "./CreateUserModal"
import { useHistory } from "react-router-dom"
import { AppContext } from "../../context"
import ActionCol from "../../grid/ActionCol"
import { FacilityPortalContext } from "./context"


export default function Users() {
	const [isLoading, setIsLoading] = useState<boolean>(true)
	const [users, setUsers] = useState<FacilityUser[]>()
	const [targetUser, setTargetUser] = useState<FacilityUser>()
	const [openCreateUserModal, setOpenCreateUserModal] = useState<boolean>(false)
	const [openDeleteUserModal, setOpenDeleteUserModal] = useState<boolean>(false)

	const appCtx = useContext(AppContext)
	const ctx = useContext(FacilityPortalContext)
	const history = useHistory()

	useEffect(() => {
		load()
	}, [])

	const load = () => {
		setIsLoading(true)
		API.getFacilityUsers(ctx.facility!.id)
		.then(({ users }) => {
			setUsers(users)
		})
		.catch(error => {
			console.error(error)
			if (error.response.status === 401) {
				appCtx.logout()
				history.push('/login')
			}
		})
		.finally(() => setIsLoading(false))
	}

	const onDeleteUser = (user: FacilityUser): Promise<any> => {
		return API.deleteFacilityUser(ctx.facility!.id, user.id)
		.then(() => {
			load()
			setOpenDeleteUserModal(false)
			setTargetUser(undefined)
		})
		.catch(console.error)
	}

	const columns: GridColDef[] = [
		{
			field: 'email',
			headerName: 'Email',
			width: 200
		},
		{
			field: 'name',
			headerName: 'Name',
			width: 150
		},
		{
			field: 'signature',
			headerName: 'Signature',
			width: 120,
			hideSortIcons: true,
			renderCell: ({ row }) => row.hasSignature
				? <img src={row.signature} height={50} />
				: <span className='text-3'>No signature</span>
		},
		ActionCol<FacilityUser>(
			<>
				{ctx.facility?.isAdmin && <IconButton 
					icon={<IconPlus />} 
					text="Create user"
					color="var(--color-theme)"
					onPress={() => {
						setOpenCreateUserModal(true)
						setTargetUser(undefined)
					}} 
				/>}
			</>,
			(row: FacilityUser) => (<>
				{ctx.facility?.isAdmin && !(row.id === ctx.user!.id) && <IconButton 
					icon={<IconDelete />} 
					color="var(--color-red)"
					onPress={() => {
						setTargetUser(row)
						setOpenDeleteUserModal(true)
					}} 
				/>}
				{row.id === ctx.user!.id && <IconButton 
					icon={<IconEdit />} 
					color="var(--color-theme)"
					onPress={() => {
						setTargetUser(row)
						setOpenCreateUserModal(true)
					}} 
				/>}
			</>)
		)
	]

	return (
		<div className="FacilityPortal-grid">
			{isLoading && <CircularProgress style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 32 }} />}
			{!isLoading && users && <DataGrid 
				getRowId={(row) => row.email}
				rows={users}
				columns={columns}
				sx={{ 
					backgroundColor: 'white'
				}}
				components={{
					NoRowsOverlay: () => NoRows("users")
				}}
				hideFooter
			/>}
			<Dialog 
				open={targetUser !== undefined && openDeleteUserModal} 
				onClose={() => {
					setOpenDeleteUserModal(false)
					setTargetUser(undefined)
				}}
			>
				<DialogTitle>Delete user {targetUser?.name}?</DialogTitle>
				<DialogActions>
					<Button onClick={() => {
						setOpenDeleteUserModal(false)
						setTargetUser(undefined)
					}}>Cancel</Button>
					<IconButton 
						color="var(--color-red)" 
						icon={<IconDelete />}
						text="Delete"
						style={{ paddingRight: 12, paddingLeft: 12 }}
						onPress={() => onDeleteUser(targetUser!)}
					/>
				</DialogActions>
			</Dialog>
			{openCreateUserModal && <CreateUserModal 
				updateUser={targetUser}
				open={openCreateUserModal}
				onClose={(refresh?: boolean) => {
					setOpenCreateUserModal(false)
					if (refresh) {
						load()
					}
				}}
			/>}
		</div>
	)
}