import { FormControlLabel, FormGroup, Grid, Switch, Box } from "@mui/material"
import React, { useCallback, useContext, useMemo, useState } from "react"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import NumericInput from 'material-ui-numeric-input'
import moment from "moment"
import { DataGrid, GridCellEditCommitParams, GridColDef, GridRenderCellParams } from "@mui/x-data-grid"
import TimePicker from 'react-time-picker'
import { FacilityPortalContext } from "./context"
import API, { API_URL } from "../../api"


const EditTimeCell = (props: GridRenderCellParams<string>) => {
	const { id, value, api, field } = props

	return <TimePicker 
		disableClock
		clearIcon={null}
		value={value && moment(value, 'h:mm a').isValid() ? moment(value, 'h:mm a').format('HH:mm') : ""}
		onChange={async e => {
			const formattedValue = moment(e, 'HH:mm').format('h:mm A')
			api.setEditCellValue({ id, field, value: formattedValue }, { target: { value: formattedValue }})
		}}
	/>
}

const Section = (props: React.PropsWithChildren<{ title: string, headerRight?: JSX.Element }>) => (
	<Box sx={{
		border: '1px solid var(--color-border)',
		backgroundColor: 'white',
		borderRadius: 1,
		mb: 2,
		p: 2,
	}}>
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<b className="subtitle" style={{ display: 'block' }}>{props.title.toUpperCase()}</b>
			<span style={{ marginLeft: 'auto' }}>{props.headerRight && props.headerRight}</span>
		</div>
		{props.children && <div style={{ marginTop: 8 }}>
			{props.children}
		</div>}
	</Box>
)

export default function Settings() {
	const ctx = useContext(FacilityPortalContext)
	const [covidTestCost, setCovidTestCost] = useState<string>(ctx.facility?.covidTestCost.toFixed(2) ?? "")
	const [name, setName] = useState<string>(ctx.facility!.name)
	const [isLoadingCalendarLink, setIsLoadingCalendarLink] = useState<boolean>(false)
	const [calendarLink, setCalendarLink] = useState<string | undefined>(API.buildCalendarLink(ctx.facility!.id, ctx.facility!.iCalToken))
	const [maxBookings, setMaxBookings] = useState<number | undefined>(ctx.facility!.maxBookings)
	
	const renderTimeEditCell = (params: any) => <EditTimeCell {...params} />

	const columns: GridColDef[] = useMemo(() => [
		{
			field: 'day',
			headerName: 'Day',
			sortable: false,
			valueFormatter: ({ value }) => ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"][value as number]
		},
		{
			field: 'isOpen',
			headerName: "Is Open",
			type: 'boolean',
			sortable: false,
			editable: true
		},
		{
			field: 'opensAt',
			headerName: 'Open At',
			width: 120,
			editable: true,
			sortable: false,
			renderEditCell: renderTimeEditCell
		},
		{
			field: 'closesAt',
			headerName: 'Close At',
			width: 120,
			editable: true,
			sortable: false,
			renderEditCell: renderTimeEditCell
		},
		...(ctx.facility?.isTelehealth ? [{
			field: 'opensAtVirtual',
			headerName: 'Virtual Open At',
			width: 120,
			editable: true,
			sortable: false,
			renderEditCell: renderTimeEditCell
		},
		{
			field: 'closesAtVirtual',
			headerName: 'Virtual Close At',
			width: 120,
			editable: true,
			sortable: false,
			renderEditCell: renderTimeEditCell
		}] : [])
	], [ctx.facility?.isTelehealth])

	if (ctx.user === undefined) return <span />

	const handleCellEditCommit = (params: GridCellEditCommitParams) => {
		const s = [...ctx.facility!.schedule ?? []]
		const idx = s.findIndex(ss => ss.day === params.id)
		if (params.field === "isOpen") {
			if (params.value === false) {
				s[idx].opensAt = null
				s[idx].closesAt = null
			}
			s[idx].isOpen = params.value as boolean
		} else if (params.field === "opensAt") {
			s[idx].opensAt = params.value?.toString() ?? s[idx].opensAt
		} else if (params.field === "closesAt") {
			s[idx].closesAt = params.value?.toString() ?? s[idx].closesAt
		} else if (params.field === "opensAtVirtual") {
			s[idx].opensAtVirtual = params.value?.toString() ?? s[idx].opensAtVirtual
		} else if (params.field === "closesAtVirtual") {
			s[idx].closesAtVirtual = params.value?.toString() ?? s[idx].closesAtVirtual
		}
		ctx.setFacility({ ...ctx.facility!, schedule: s })
	}

	const getCalendarLink = () => {
		setIsLoadingCalendarLink(true)
		API.getICalendarToken(ctx.facility!.id)
		.then(({ iCalToken }) => setCalendarLink(API.buildCalendarLink(ctx.facility!.id, iCalToken)))
		.catch(alert)
		.finally(() => setIsLoadingCalendarLink(false))
	}

	return (
		<>
			<Grid container spacing={2} sx={{ flex: 1 }}>
				<Grid item xs={6} sx={{ width: '100%' }}>
					<Section title="Basic">
						<Input
							fullWidth
							label="Facility Name"
							error={!name}
							value={name}
							onChange={e => {
								setName(e.target.value)
								ctx.setFacility({ ...ctx.facility!, name: e.target.value })
							}}
						/>
						<Input 
							label="Maximum bookings per 15 minute time slot"
							value={ctx.facility?.maxBookings}
							onChange={e => {
								let value: number | undefined
								if (Number(e.target.value)) {
									value = Number(e.target.value)
								}
								ctx.setFacility({ ...ctx.facility!, maxBookings: value })
							}}
							type="number"
							inputMode="numeric"
							inputProps={{ pattern: '[0-9]*' }}
						/>
					</Section>
					<Section title="COVID testing" headerRight={
						<FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
							<FormControlLabel control={
								<Switch 
									checked={ctx.facility?.isCovidLive}
									onChange={e => {
										ctx.setFacility({ ...ctx.facility!, isCovidLive: e.target.checked })
									}}
								/>
							} label="Open for COVID testing" />
						</FormGroup>
					}>
						<Input 
							fullWidth
							label="COVID-19 Test Price"
							placeholder="40.00"
							value={covidTestCost}
							onChange={e => {
								const val = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1')
								setCovidTestCost(val)
								if (Number(val)) {
									ctx.setFacility({ ...ctx.facility!, covidTestCost: Number(val) })
								}
							}}
							error={!Number(covidTestCost)}
							/*helperText="HealthIQ charges 5% of the price for each test result."*/
							inputMode="numeric"
							type="number"
							InputProps={{
								startAdornment: <span className="text-2" style={{ marginRight: 8 }}>$</span>
							}}
						/>
					</Section>
					<Section title="Telehealth" headerRight={
						<FormGroup>
							<FormControlLabel control={
								<Switch 
									checked={ctx.facility?.isTelehealth}
									onChange={e => {
										ctx.setFacility({ ...ctx.facility!, isTelehealth: e.target.checked })
									}}
								/>
							} label="Allow virtual bookings" />
						</FormGroup>
					}>
						{ctx.facility?.isTelehealth && <Input
							label="Telehealth meeting URL"
							value={ctx.facility.telehealthUrl}
							onChange={e => {
								ctx.setFacility({ ...ctx.facility!, telehealthUrl: e.target.value })
							}}
							inputMode="url"
							type="url"
							placeholder="https://"
							helperText="Remember to setup a waiting room so that patients can't join your other sessions"
						/>}
					</Section>
					<Section title="iCalendar" headerRight={<>
						<ThemedButton 
							variant="text"
							onClick={getCalendarLink} 
							disabled={isLoadingCalendarLink}
							style={{ maxWidth: 200 }}
						>
							NEW LINK
						</ThemedButton>
					</>}>
						{calendarLink && !isLoadingCalendarLink && <Input 
							label="iCalendar link"
							value={calendarLink}
							InputProps={{
								readOnly: true
							}}
							helperText="Import this into your email client to see bookings in your calendar"
						/>}
					</Section>
				</Grid>
				<Grid item xs={6} className="FacilityPortal-grid">
					<DataGrid 
						getRowId={(row) => row.day}
						rows={ctx.facility!.schedule}
						columns={columns}
						onCellEditCommit={handleCellEditCommit}
						hideFooter
						sx={{ backgroundColor: 'white' }}
					/>
				</Grid>
			</Grid>
		</>
	)
}