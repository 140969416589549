import React from "react"
import { alpha, styled } from "@mui/material/styles"
import { FormControl, InputLabel, Select, SelectProps } from "@mui/material"

const ThemedSelect = (props: SelectProps) => (
	<FormControl 
		variant="standard" 
		sx={{ mt: 1.5, width: '100%' }}
	>
		<InputLabel 
			sx={{
				color: 'var(--color-text3)'
			}}
		>
			{props.placeholder}
		</InputLabel>
		<Select
			variant="standard"
			sx={{
				'&:before': {
					borderBottomColor: 'var(--color-border) !important',
				},
				'&:hover:before': {
					borderBottomColor: 'var(--color-border) !important',
				},
				color: 'var(--color-text)'
			}}
			{...props}
		>
			{props.children}
		</Select>
	</FormControl>
)

export default ThemedSelect