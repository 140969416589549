import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles'
import PickersDay, { PickersDayProps } from '@mui/lab/PickersDay'
import { StaticDateTimePicker, DatePicker, LocalizationProvider, TimePicker, DateTimePicker, StaticDatePicker } from '@mui/lab'
import AdapterMoment from '@mui/lab/AdapterMoment'
import moment from 'moment';
import Input from './Input';
import { Facility, FacilitySchedule } from '../models';

type CustomPickerDayProps = PickersDayProps<moment.Moment> & {
	isOpen: boolean
}

const CustomPickersDay = styled(PickersDay, {
	shouldForwardProp: (prop) =>
		prop !== 'isOpen',
})<CustomPickerDayProps>(({ theme, isOpen }) => ({
	backgroundColor: isOpen ? 'var(--color-foreground)' : 'var(--color-red-15)'
})) as React.ComponentType<CustomPickerDayProps>

type Props = {
	facility: Facility
	value: moment.Moment | null
	onChange: (_: moment.Moment | null) => void
	error: boolean
}

export default function SchedulePicker(props: Props) {
	const [schedule, setSchedule] = useState<FacilitySchedule>()

	useEffect(() => {
		if (!props.facility || !props.value) return;

		let s = props.facility.schedule.find(s => s.day === props.value?.weekday())
		setSchedule(s)
	}, [props.facility, props.value])

	const renderWeekPickerDay = (
		date: moment.Moment,
		selectedDates: Array<moment.Moment | null>,
		pickersDayProps: PickersDayProps<moment.Moment>,
	) => {
		let s = props.facility.schedule.find(s => s.day === date.weekday())

		return (
			<CustomPickersDay
				{...pickersDayProps}
				disabled={pickersDayProps.disabled || !(s?.isOpen ?? false)}
				isOpen={s?.isOpen ?? false}
			/>
		)
	}

	return (
		<LocalizationProvider dateAdapter={AdapterMoment}>
			<StaticDatePicker
				displayStaticWrapperAs="desktop"
				disablePast={true}
				views={['day']}
				/* if not allowed same-day bookings: shouldDisableDate={d => d.isSameOrBefore()}*/
				maxDate={moment().add(1, 'week')}
				mask="__/__/____"
				label="Select date"
				disabled={!props.facility}
				value={props.value}
				onChange={(value) => {
					const date = value?.format('YYYY-MM-DD')
					const time = props.value?.format('HH:mm')
					props.onChange(moment(date + ' ' + time, 'YYYY-MM-DD HH:mm'))
				}}
				renderDay={renderWeekPickerDay}
				renderInput={(params) => <Input {...params} error={props.error} fullWidth />}
			/>
		</LocalizationProvider>
	);
}