import { GridColDef } from "@mui/x-data-grid"
import React from "react"

export default function ActionCol<T>(header: JSX.Element, actions: (row: T) => JSX.Element): GridColDef {
	return {
		field: 'actions',
		type: 'actions',
		headerName: '',
		flex: 1,
		minWidth: 150,
		headerAlign: 'right',
		hideSortIcons: true,
		renderHeader: () => (
			<div style={{ marginLeft: 'auto', marginRight: 16 }}>
				{header}
			</div>
		),
		renderCell: ({ row }) => (
			<div className="row GridActionCell" style={{ marginLeft: 'auto' }}>
				{actions(row as T)}
			</div>
		)
	}
}