import React, { useState, useEffect, useContext } from "react"
import { Autocomplete, Checkbox, FormControlLabel, Grid, MenuItem, Modal } from "@mui/material"
import { ReactComponent as IconPlusColor } from "../assets/icons/PlusColor.svg"
import CloseButton from "./CloseButton"
import ThemedButton from "./Button"
import API from "../api"
import { CovidTestReason, Patient, UpdatePatientRequest } from "../models"
import moment from "moment"
import ThemedSelect from "./Select"
import Input from "./Input"
import { LocalizationProvider, DesktopDatePicker } from "@mui/lab"
import DateAdapter from '@mui/lab/AdapterMoment'
import { AppContext } from "../context"


type Props = {
	passportNumberRequired?: boolean
	open: boolean
	onClose: (dependents?: Patient[]) => void
}

export default function CreateDependentModal({ open, onClose, passportNumberRequired }: Props) {
	const appCtx = useContext(AppContext)
	
	const [didNext, setDidNext] = useState<boolean>(false)
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
	
	const [firstName, setFirstName] = useState<string>()
	const [middleName, setMiddleName] = useState<string>()
	const [lastName, setLastName] = useState<string>()
	const [dateOfBirth, setDateOfBirth] = useState<moment.Moment | null>(null)
	const [sex, setSex] = useState<string>()
	const [passportNumber, setPassportNumber] = useState<string>()
	const [gpId, setGpId] = useState<number>()
	const [isCovidVaccinated, setIsCovidVaccinated] = useState<boolean>(false)

	const onSubmit = () => {
		setDidNext(true)
		if (!firstName || !lastName || !sex || !dateOfBirth || !dateOfBirth.isValid() || (passportNumberRequired && !passportNumber)) {
			return
		} 

		const request: UpdatePatientRequest = {
			firstName,
			middleName,
			lastName,
			dateOfBirth: dateOfBirth!.tz('America/Halifax', true).format(),
			sex,
			passportNumber,
			gpId,
			isCovidVaccinated
		}

		setIsSubmitting(true)
		return API.createDependent(request)
		.then(({ dependents }) => onClose(dependents))
		.catch(alert)
		.finally(() => setIsSubmitting(false))
	}

	return (
		<Modal 
			open={open} 
			onClose={() => onClose()}
			sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<div className="Card" style={{ maxWidth: 400 }}>
				<CloseButton onClose={() => onClose()} />
				<div className="Card-title">
					<h1>Add dependent</h1>
				</div>
				<Grid container sx={{ pb: 2, px: 4 }} direction="column">
					<Grid item container direction="row" spacing={2}>
						<Grid item xs>
							<Input 
								label="First Name" 
								value={firstName}
								onChange={e => setFirstName(e.target.value)}
								autoComplete="given-name"
								error={!firstName && didNext}
							/>
						</Grid>
						<Grid item xs>
							<Input 
								label="Middle Name" 
								value={middleName}
								onChange={e => setMiddleName(e.target.value)}
								autoComplete="middle-name"
							/>
						</Grid>
						<Grid item xs>
							<Input 
								label="Last Name" 
								value={lastName}
								onChange={e => setLastName(e.target.value)}
								autoComplete="family-name"
								error={!lastName && didNext}
							/>
						</Grid>
					</Grid>
					<Grid item container direction="row" spacing={2}>
						<Grid item xs>
							<LocalizationProvider dateAdapter={DateAdapter}>
								<DesktopDatePicker
									disableOpenPicker
									disableFuture
									mask="__/__/____"
									label="Date of birth"
									value={dateOfBirth}
									onChange={(value) => setDateOfBirth(value)}
									renderInput={(params) => 
										<Input {...params} 
											inputMode="numeric"
											error={didNext && (!dateOfBirth || !dateOfBirth.isValid())} 
										/>
									}
								/>
							</LocalizationProvider>
						</Grid>
						<Grid item xs>
							<ThemedSelect 
								placeholder="Sex" 
								value={sex} 
								onChange={e => setSex(e.target.value as string)}
								error={!sex && didNext}
							>
								<MenuItem value="Male">Male</MenuItem>
								<MenuItem value="Female">Female</MenuItem>
								<MenuItem value="Other">Other</MenuItem>
							</ThemedSelect>
						</Grid>
					</Grid>
					{passportNumberRequired && <Grid item xs>
						<Input 
							label="Passport number" 
							value={passportNumber}
							onChange={e => setPassportNumber(e.target.value)}
							error={(passportNumberRequired && !passportNumber) && didNext}
							helperText={passportNumber ? "Required for travel document verification" : undefined}
						/>
					</Grid>}
					<Grid item xs>
						<Autocomplete 
							key="gp-input"
							clearOnEscape={false}
							clearOnBlur={false}
							options={appCtx.GPs?.sort((a,b) => a.name.split(' ')[1].localeCompare(b.name.split(' ')[1])) ?? []}
							getOptionLabel={opt => opt.name}
							value={appCtx.GPs?.find(x => x.id === gpId)}
							onChange={(e, value) => setGpId(value?.id ?? undefined)}
							renderInput={params => 
								<Input {...params} 
									label="Current GP (optional)"
								/>
							}
						/>
					</Grid>
					<Grid item sx={{ mt: 2 }}>
						<FormControlLabel control={
							<Checkbox 
								size="medium" 
								value={isCovidVaccinated} 
								checked={isCovidVaccinated}
								onChange={e => setIsCovidVaccinated(e.target.checked)}
							/>} 
							label="COVID-19 vaccinated (optional)" 
						/>
					</Grid>
					<ThemedButton 
						style={{ marginTop: 24 }} 
						onClick={onSubmit}
						disabled={isSubmitting}
					>
						SAVE
					</ThemedButton>
				</Grid>
			</div>
		</Modal>
	)
}