import React, { useContext, useEffect, useState } from "react"
import { Button, Grid, MenuItem, TextField, FormControl, RadioGroup, FormControlLabel, Radio, LinearProgress } from "@mui/material"
import { ReactComponent as IconReview } from "../../assets/icons/Review.svg"
import ThemedButton from "../../components/Button"
import Input from "../../components/Input"
import { determineCardType, formatCardExpiration, formatCardNumber, formatCurrency } from "../../util"
import { CovidTestReason, formatAddress, getPaymentTypeIcon, PaymentType } from "../../models"
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import { AppContext } from "../../context"
import API from "../../api"
import { useHistory } from "react-router-dom"
import { DEFAULT_COVID_TEST_COST } from "../../const"

export default function Review(props: INavigate) {
	const appCtx = useContext(AppContext)
	const ctx = useContext(CovidTestsContext)

	const history = useHistory()

	const [isBooking, setIsBooking] = useState<boolean>(false)

	const bookTest = () => {
		setIsBooking(true)
		API.bookCovidTest(ctx)
		.then(({ booking }) => {
			appCtx.setCovidTestBooking(booking)
			history.push(`/booking/${booking.id}`)
		})
		.catch(e => {
			alert(e.response?.data?.message ?? 'Failed to book test, please try again.')
		})
		.finally(() => setIsBooking(false))
	}

	return (
		<div className="Card">
			<div className="Card-title">
				<h1>Review</h1>
				<IconReview />
			</div>
			<div style={{ marginTop: 16 }}>
				{isBooking && <LinearProgress />}
			</div>
			<div className="Table-row">
				Name
				<span>
					{ctx.firstName} {ctx.middleName} {ctx.lastName}
				</span>
			</div>
			<div className="Table-row">
				Date of Birth
				<span>
					{ctx.dateOfBirth?.format('MMMM Do, YYYY')}
				</span>
			</div>
			<div className="Table-row">
				Email
				<span>
					{ctx.email}
				</span>
			</div>
			<div className="Table-row">
				Phone number
				<span>
					{ctx.phoneValue}
				</span>
			</div>
			{ctx.passportNumber && ctx.testReason === CovidTestReason.travel && <div className="Table-row">
				Passport number
				<span>
					{ctx.passportNumber}
				</span>
			</div>}
			<div className="Table-row">
				Home address
				<span>
					{ctx.homeAddress}, {ctx.parish} {ctx.postcode}
				</span>
			</div>
			<div className="Table-row">
				Test location
				<span>
					{ctx.facility?.name}
				</span>
			</div>
			<div className="Table-row">
				Appointment
				<span>
					{ctx.isTelehealth ? 'Virtual' : 'In-Person'}
				</span>
			</div>
			<div className="Table-row">
				Test time
				<span>
					{ctx.date?.format('MMMM Do, h:mm a')}
				</span>
			</div>
			{ctx.numBookings > 1 && <div className="Table-row">
				Booked for
				<span>
					{ctx.numBookings} people
				</span>
			</div>}
			<div className="Table-row">
				Payment
				<span>
					{formatCurrency(ctx.numBookings * (ctx.facility?.covidTestCost ?? DEFAULT_COVID_TEST_COST))}
				</span>
			</div>
			<Grid container direction="column" className="Card-form" sx={{ p: 4, py: 1 }}>
				<Grid container direction="row" spacing={2} sx={{ my: 1 }}>
					<Grid item xs>
						<ThemedButton variant="text" onClick={props.goBack}>PREVIOUS</ThemedButton>
					</Grid>
					<Grid item xs>
						<ThemedButton onClick={bookTest} disabled={isBooking}>BOOK TEST</ThemedButton>
					</Grid>
				</Grid>
			</Grid>
		</div>
	)
}
