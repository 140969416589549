import { Fade, Slide } from "@mui/material";
import LoginProvider, { LoginContext, LoginViewMode } from "./context";
import Login from "./Login";
import { TransitionGroup } from 'react-transition-group'
import { useContext, useMemo } from "react";
import Register from "./Register";
import OTP from "./OTP";
import { User } from "../../models";

export interface ILoginNavigate {
	allowProviders?: boolean
	onLogin: (user: User, mode: LoginViewMode) => void
	goBack?: () => void
}

export interface INavigate {
	goBack: () => void
	goNext: () => void
}

function LoginFlow(props: ILoginNavigate) {
	const ctx = useContext(LoginContext)
	
	const Card = useMemo(() => ({ show, initial, children }: React.PropsWithChildren<{ show: boolean, initial?: boolean }>) => {
		const renderSlide = (
			<Fade in={show} timeout={400} className={show ? 'visible' : undefined}>
				<div>
					<Slide 
						direction="left"
						in={show}
						timeout={200}
						mountOnEnter
					>
						<div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
							{children}
						</div>
					</Slide>
				</div>
			</Fade>
		)
		return (initial ?
			<TransitionGroup>
				{renderSlide}
			</TransitionGroup>
			:
			<>{renderSlide}</>
		)
	}, [ctx.isOtp, ctx.mode])

	return (<>
		<Card show={!ctx.isOtp && ctx.mode === "login"}>
			<Login {...props} /> 
		</Card>
		<Card show={!ctx.isOtp && ctx.mode === "register"}>
			<Register {...props} /> 
		</Card>
		<Card show={ctx.isOtp}>
			<OTP {...props} /> 
		</Card>
	</>)
}

export default function LoginFlowHOC(props: ILoginNavigate & { startMode: LoginViewMode }) {
	return (<div className="">
		<LoginProvider startMode={props.startMode}>
			<LoginFlow {...props} />
		</LoginProvider>
	</div>)
}