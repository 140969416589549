import React, { useContext, useState } from "react"
import API from "../../api"
import { ReactComponent as IconLab } from "../../assets/icons/Lab.svg"
import { VerifyContext } from "./context"
import { LinearProgress } from "@mui/material"


export default function Processing() {
	return (
		<div className="Card">
			<div className="Card-title">
				<h1>
					Processing...
				</h1>
				<IconLab />
			</div>
			<div className="Card-message">
				Your COVID-19 antigen test is still being processed. Please try again later.
			</div>
			<LinearProgress />
		</div>
	)
}
