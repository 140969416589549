import { Grid } from "@mui/material"
import React, { useContext } from "react"
import TabSelect from "../../components/TabSelect"
import { FacilityUserAssociation, formatAddress } from "../../models"
import { FacilityPortalContext } from "./context"



export default function SelectFacility() {
	const ctx = useContext(FacilityPortalContext)

	const selectFacility = (facility: FacilityUserAssociation) => {
		ctx.setFacility(facility)
	}

	return (<div className="Slides">
		<div className="Card">
			<div className='Card-title'>
				<h1>Select Facility</h1>
			</div>
			<div className='Card-message'>
				You are a member of multiple facilities – select one to view now.
			</div>
			<Grid container sx={{ px: 2, py: 1 }}>
				{ctx.user?.facilities.map(f => <TabSelect onClick={() => selectFacility(f)}>
					<div style={{ flexGrow: 1 }}>
						<div>{f.name}</div>
						<span className='text-3'>{formatAddress(f.address)}</span>
					</div>
				</TabSelect>)}
			</Grid>
		</div>
	</div>)
}