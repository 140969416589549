import { PaymentType } from "./models"

export const formatCurrency = (value: number) => {
	return value.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
}

export const isValidEmail = (email: string) => {
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	return re.test(String(email).toLowerCase())
}

export const capitalizeFirstLetter = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
}

export const flagEmoji = (countryCode: string) => {
	const alphaToFlagAlpha = (a: string) => String.fromCodePoint(0x1f1a5 + a.toUpperCase().codePointAt(0)!)
	return countryCode.slice(0, 2).split("").map(alphaToFlagAlpha).join("")
}

export const determineCardType = (value: string) => {
	const v = formatCardNumber(value).replace(/\s/g,'')
	const isVisa = /^4[0-9]{12}(?:[0-9]{3})?$/.test(v)
	const isMastercard = /^(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/.test(v)
	const isAmex = /^3[47][0-9]{13}$/.test(v)
	const isDinersClub = /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/.test(v)
	const isDiscover = /^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$/.test(v)
	
	let cardType: PaymentType | undefined
	if (isVisa) {
		cardType = PaymentType.visa
	} else if (isMastercard) {
		cardType = PaymentType.mastercard
	} else if (isAmex) {
		cardType = PaymentType.amex
	} else if (isDinersClub) {
		cardType = PaymentType.dinersClub
	} else if (isDiscover) {
		cardType = PaymentType.discover
	} else {
		cardType = undefined
	}
	return cardType
}

export const formatCardNumber = (value: string) => {
	var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
	var matches = v.match(/\d{4,16}/g);
	var match = matches && matches[0] || ''
	var parts = []

	for (var i = 0, len=match.length; i<len; i+=4) {
		parts.push(match.substring(i, i+4))
	}

	if (parts.length) {
		return parts.join(' ')
	} else {
		return v
	}
}

export const formatCardExpiration = (value: string) => {
	return value.replace(
		/[^0-9]/g, '' // To allow only numbers
	).replace(
		/^([2-9])$/g, '0$1' // To handle 3 > 03
	).replace(
		/^(1{1})([3-9]{1})$/g, '0$1 / $2' // 13 > 01/3
	).replace(
		/^0{1,}/g, '0' // To handle 00 > 0
	).replace(
		/^([0-1]{1}[0-9]{1})([0-9]{1,2}).*/g, '$1 / $2' // To handle 113 > 11/3
	)
}