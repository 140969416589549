import React, { useContext, useEffect, useState } from "react"
import { INavigate } from "."
import { CovidTestsContext } from "./context"
import { AppContext } from "../../context"
import LoginFlowHOC from "../Login"

export default function RegisterLogin(props: INavigate) {
	return (
		<LoginFlowHOC 
			startMode="register"
			allowProviders={false} 
			onLogin={(user, mode) => {
				if (mode === "register") {
					props.goNext()
				}
			}}
			goBack={props.goBack}
		/>
	)
}
