import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Map from '../../components/Map'
import CovidTestsProvider, { CovidTestsContext } from './context'
import { Facility } from '../../models'
import { Fade, Slide } from '@mui/material'
import { TransitionGroup } from 'react-transition-group'
import RegisterLogin from './RegisterLogin'
import Address from './Address'
import Schedule from './Schedule'
import Agreement from './Agreement'
import PaymentMethod from './PaymentMethod'
import Review from './Review'
import './index.css'
import Onboard from './Onboard'
import { AppContext } from '../../context'
import Dependents from './Dependents'

export interface INavigate {
	goNext: () => void
	goBack: () => void
}

function CovidTestsMap() {
	const ctx = useContext(CovidTestsContext)

	const [step, setStep] = useState<number>(0)
	const [isBackwardsAnimation, setIsBackwardsAnimation] = useState<boolean>(false)

	const goBack = () => {
		setIsBackwardsAnimation(true)
		setStep(Math.max(0, step - 1))
	}

	const goNext = () => {
		setIsBackwardsAnimation(false)
		setStep(step + 1)
	}

	const Card = useMemo(() => ({ index, children }: React.PropsWithChildren<{ index: number }>) => {
		console.log("RENDER CARD HERE>")
		const renderSlide = (
			<Fade 
				in={step === index} 
				timeout={400} 
				className={step === index ? 'visible' : undefined}
			>
				<div>
					<Slide 
						direction={isBackwardsAnimation ? "right" : "left"} 
						in={step === index}
						timeout={200}
						mountOnEnter
					>
						<div style={{ 
							display: 'flex', 
							flexDirection: 'column',
							width: '100%', 
							justifyContent: 'center', 
							alignItems: 'center' 
						}}>
							{children}
						</div>
					</Slide>
				</div>
			</Fade>
		)
		return (index === 0 ?
			<TransitionGroup>
				{renderSlide}
			</TransitionGroup>
			:
			<>{renderSlide}</>
		)
	}, [step])

	return (<>
		<Map 
			isLocked={step > 0}
			facilities={ctx.facilities}
			activeFacility={ctx.facility}
			setActiveFacility={ctx.setFacility}
		>
			<div className='Slides'>
				<Card index={0}>
					<Onboard goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={1}>
					<RegisterLogin goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={2}>
					<Address goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={3}>
					<Dependents goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={4}>
					<Schedule goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={5}>
					<Agreement goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={6}>
					<PaymentMethod goBack={goBack} goNext={goNext} />
				</Card>
				<Card index={7}>
					<Review goBack={goBack} goNext={goNext} />
				</Card>
			</div>
		</Map>
	</>)
}

export default function CovidTestsHOC() {
	return (
		<CovidTestsProvider>
			<CovidTestsMap />
		</CovidTestsProvider>
	)
}