import { ReactComponent as IconVisa } from "./assets/icons/CardVisa.svg"
import { ReactComponent as IconMastercard } from "./assets/icons/CardMastercard.svg"
import { ReactComponent as IconAmex } from "./assets/icons/CardAmex.svg"

import { ReactComponent as IconAlternative } from "./assets/icons/facility/Alternative.svg"
import { ReactComponent as IconAlternativeFilled } from "./assets/icons/facility/AlternativeFilled.svg"
import { ReactComponent as IconChiropody } from "./assets/icons/facility/Chiropody.svg"
import { ReactComponent as IconChiropodyFilled } from "./assets/icons/facility/ChiropodyFilled.svg"
import { ReactComponent as IconClinicalLaboratory } from "./assets/icons/facility/ClinicalLaboratory.svg"
import { ReactComponent as IconClinicalLaboratoryFilled } from "./assets/icons/facility/ClinicalLaboratoryFilled.svg"
import { ReactComponent as IconCounseling } from "./assets/icons/facility/Counseling.svg"
import { ReactComponent as IconCounselingFilled } from "./assets/icons/facility/CounselingFilled.svg"
import { ReactComponent as IconDefault } from "./assets/icons/facility/Default.svg"
import { ReactComponent as IconDefaultFilled } from "./assets/icons/facility/DefaultFilled.svg"
import { ReactComponent as IconDentist } from "./assets/icons/facility/Dentist.svg"
import { ReactComponent as IconDentistFilled } from "./assets/icons/facility/DentistFilled.svg"
import { ReactComponent as IconDiagnostics } from "./assets/icons/facility/Diagnostics.svg"
import { ReactComponent as IconDiagnosticsFilled } from "./assets/icons/facility/DiagnosticsFilled.svg"
import { ReactComponent as IconHomecare } from "./assets/icons/facility/Homecare.svg"
import { ReactComponent as IconHomecareFilled } from "./assets/icons/facility/HomecareFilled.svg"
import { ReactComponent as IconMedicalOffice } from "./assets/icons/facility/MedicalOffice.svg"
import { ReactComponent as IconMedicalOfficeFilled } from "./assets/icons/facility/MedicalOfficeFilled.svg"
import { ReactComponent as IconMedicalSupplies } from "./assets/icons/facility/MedicalSupplies.svg"
import { ReactComponent as IconMedicalSuppliesFilled } from "./assets/icons/facility/MedicalSuppliesFilled.svg"
import { ReactComponent as IconPharmacy } from "./assets/icons/facility/Pharmacy.svg"
import { ReactComponent as IconPharmacyFilled } from "./assets/icons/facility/PharmacyFilled.svg"
import { ReactComponent as IconPsychology } from "./assets/icons/facility/Psychology.svg"
import { ReactComponent as IconPsychologyFilled } from "./assets/icons/facility/PsychologyFilled.svg"
import { ReactComponent as IconSchool } from "./assets/icons/facility/School.svg"
import { ReactComponent as IconSchoolFilled } from "./assets/icons/facility/SchoolFilled.svg"
import { ReactComponent as IconVision } from "./assets/icons/facility/Vision.svg"
import { ReactComponent as IconVisionFilled } from "./assets/icons/facility/VisionFilled.svg"

export enum FacilityType {
	chiropody = 'Chiropody',
	clinical_laboratory = 'Clinical Laboratory',
	alternative = 'Complementary & Alternative Medicine',
	dental = 'Dental',
	diagnostic_imaging = 'Diagnostic Imaging',
	homecare = 'Homecare',
	medical_office = 'Medical Office',
	medical_supplies = 'Medical Supplies',
	mental_health = 'Mental Health & Counseling',
	pharmacy = 'Pharmacy',
	psychology = 'Psychology',
	school = 'School',
	vision_care = 'Vision Care'
}

export const getFacilityTypeIcon = (type: FacilityType | undefined, filled: boolean) => {
	switch (type) {
		case FacilityType.chiropody: 
			return filled ? <IconChiropodyFilled /> : <IconChiropody />
		case FacilityType.clinical_laboratory: 
			return filled ? <IconClinicalLaboratoryFilled /> : <IconClinicalLaboratory />
		case FacilityType.alternative: 
			return filled ? <IconAlternativeFilled /> : <IconAlternative />
		case FacilityType.dental:
			return filled ? <IconDentistFilled /> : <IconDentist />
		case FacilityType.diagnostic_imaging: 
			return filled ? <IconDiagnosticsFilled /> : <IconDiagnostics />
		case FacilityType.homecare: 
			return filled ? <IconHomecareFilled /> : <IconHomecare />
		case FacilityType.medical_office:
			return filled ? <IconMedicalOfficeFilled /> : <IconMedicalOffice />
		case FacilityType.medical_supplies: 
			return filled ? <IconMedicalSuppliesFilled /> : <IconMedicalSupplies />	
		case FacilityType.mental_health: 
			return filled ? <IconCounselingFilled /> : <IconCounseling />
		case FacilityType.pharmacy: 
			return filled ? <IconPharmacyFilled /> : <IconPharmacy />
		case FacilityType.psychology: 
			return filled ? <IconPsychologyFilled /> : <IconPsychology />
		case FacilityType.school: 
			return filled ? <IconSchoolFilled /> : <IconSchool />
		case FacilityType.vision_care: 
			return filled ? <IconVisionFilled /> : <IconVision />
		default: 
			return filled ? <IconDefaultFilled /> : <IconDefault />
	}
}

export type Facility = {
	id: number
	name: string
	email: string
	phone: string
	website: string
	address: Address
	schedule: FacilitySchedule[]
	covidTestCost: number
	isCovidLive: boolean
	isTelehealth: boolean
	telehealthUrl?: string
	maxBookings?: number
	iCalToken?: string
	facilityType?: string
}

export type FacilityUserAssociation = Facility & {
	isAdmin: boolean
}

export type FacilitySchedule = {
	day: number
	isOpen: boolean
	opensAt: string | null
	closesAt: string | null
	opensAtVirtual: string | null
	closesAtVirtual: string | null
}

export type Address = {
	address: string
	parish: string
	postcode: string
	country: string
	latitude?: number
	longitude?: number
}

export const formatAddress = (address?: Address) => {
	return address ? `${address.address}, ${address.parish} ${address.postcode}, ${address.country}` : ''
}

export enum PaymentType {
	visa = 1, mastercard = 2, amex = 3, dinersClub = 4, discover = 5
}

export const getPaymentTypeIcon = (type: PaymentType, size: number) => {
	switch (type) {
		case PaymentType.visa:
			return <IconVisa width={size} height={size} />
		case PaymentType.mastercard:
			return <IconMastercard width={size} height={size} />
		case PaymentType.amex:
			return <IconAmex width={size} height={size} />
		default:
			return undefined
	}
}

export type Patient = User & {
	firstName: string
	middleName: string
	lastName: string
	dateOfBirth: Date
	email: string
	sex: string
	phone: string
	passportNumber: string
	address: Address
	gpId?: number
	isCovidVaccinated?: boolean
}

export type UpdatePatientRequest = {
	firstName: string
	middleName?: string
	lastName: string
	dateOfBirth: string
	sex: string
	passportNumber?: string
	gpId?: number
	isCovidVaccinated?: boolean
}

export type CreatePatientRequest = UpdatePatientRequest & {
	email: string
	phone: string
}

export type Booking = {
	id: string
	type: "booking" | "covid_test_booking"
	patient: Patient
	reservationDate: Date
	facility: Facility
	isPaid: boolean
	price: number
	isTelehealth: boolean
	isCancelled: boolean
}

export type CovidTestBooking = Booking & {
	testResult?: CovidTestResult
	testResultAt?: Date
	testType?: CovidTestType
	hasCertificate?: boolean
}

export type Certificate = {
	name: string
	dateOfBirth: Date
	testResultAt?: Date
	testResult?: CovidTestResult
	testType?: CovidTestType
	url: string
}

export type User = {
	id: number
	type: "user" | "patient" | "facility_user"
}

export type FacilityUser = User & {
	id: number
	name: string
	email: string
	facilities: FacilityUserAssociation[]
	siganture: string
	hasSignature: boolean
}

export enum CovidTestResult {
	positive = "positive", 
	inconclusive = "inconclusive", 
	negative = "negative"
}

export enum CovidTestType {
	antigen = "antigen",
	naat = "naat"
}

export enum CovidTestReason {
	travel = "travel",
	school = "school",
	event = "event",
	work = "work",
	publicHealth = "public_health"
}

export type GP = {
	id: number
	name: string
}

export type Availability = {
	time: string
	count: number
}