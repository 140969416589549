import { Box } from "@mui/material"
import { ReactComponent as IconEmpty } from "../assets/icons/Empty.svg"

const NoRows = (entityName: string) => (
	<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translateX(-50%) translateY(-50%)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
		<IconEmpty fill="var(--color-border)" />
		<Box sx={{ mt: 1, color: 'var(--color-text2)', fontWeight: 500 }}>No {entityName}</Box>
	</div>
)

export default NoRows