export default function Privacy() {
	return (<div>
		<p>
			The services and information offered on healthiq.bm (the “Website”) are designed for
			educational purposes by the Bermuda Health Council are not intended to provide or be used
			for the diagnosis or treatment of disease or other conditions, including COVID-19. The
			guidance you receive depends on the accuracy of the information you provide as well as
			current guidelines for responding to symptoms associated with the novel coronavirus
			COVID-19.
		</p>
		<p>
			Never disregard professional medical advice or delay in seeking it because of something you
			have read on this website. If you think you or someone in your care is seriously ill or has
			a medical emergency, call a doctor or 9-1-1 immediately (if non-US, call your national
			emergency number), regardless of the information offered by the Website.
		</p>
		<p>
			The services on the Website do not constitute the practice of medicine or any other
			professional health care advice, diagnosis or treatment. Reliance on the services and
			information provided by the Website is solely at your own risk.
		</p>
		<p>By entering your information, you indicate that you have reviewed the privacy policy.</p>
		<h2>Bermuda's HealthIQ Privacy Policy</h2>

		<h3>1. Please Read Carefully</h3>
		<p>
			This Online Privacy Policy describes the information the Bermuda Health Council (“Health
			Council”) collects about you through HealthIQ.bm (the “Website”) and all other services we
			provide (collectively, the “Services”), how we use and share that information, and the
			privacy choices we offer. This policy applies to information we collect when you access or
			use our websites (collectively, the “Site”), when you use our Services or when you otherwise
			interact with us.
		</p>

		<h3>2. Changes to this Online Privacy Policy</h3>
		<p>
			We may change this Online Privacy Policy from time to time. If we make changes, we will
			notify you by posting the updated policy on our Site and revising the “Last Updated” date
			above. We encourage you to review the Online Privacy Policy whenever you use our Services to
			stay informed about our information practices and about ways you can help protect your
			privacy. Please note that changes made to this Online Privacy Policy on 1st May 2020 reflect
			the Health Council’s practices since the Website was established and shall apply to all data
			collected through the Website on or before that date.
		</p>

		<h3>3. Use of Services</h3>
		<p>
			Your access to and use of our Services are subject to certain terms and conditions, which
			are set forth in our Terms of Use.
		</p>

		<h3>4. Collection of Information</h3>
		<h4>1. Information You Provide</h4>
		<p>
			If you choose to complete the population health status indicators (including COVID-19)
			self-assessment survey, you may be asked to provide your phone number, age, gender,
			ethnicity and postal code along with information about current health symptoms (such as
			fever, dry cough, sore throat, shortness of breath, headache, and nausea) and health
			conditions (such as chronic respiratory, heart, kidney, liver or neurological disease,
			immunosuppression). The self-assessment survey does not require your name or address. If you
			would like to receive a follow-up text reminder from us to re-check your symptoms you may
			choose to select to be provided such via SMS message. Alternatively, you may email{' '}
			<a href="mailto:healthcouncil@bhec.bm">healthcouncil@bhec.bm</a> to request such reminders.
			We will not associate this information from the information you provide during the
			self-assessment, and therefore will not retain personally identifiable health information
			about you.
		</p>

		<h4>2. Children</h4>
		<p>
			The Health Council does not knowingly collect or maintain personally identifiable
			information from persons under 14 years of age without verifiable parental consent, and no
			part of the Services are directed at persons under 14. If you are under 14 years of age,
			then please do not use the Services. If the Health Council learns that personally
			identifiable information of persons less than 14 years of age has been collected without
			verifiable parental consent, then the Health Council will take the appropriate steps to
			delete this information. To make such a request, please contact us at 441-292-6420.
		</p>

		<h4>3. Information We Collect from Your Use of the Services</h4>
		<p>
			We collect information about you when you use our Site, including, but not limited to the
			following:
		</p>
		<ul>
			<li>
				Device Information. We may automatically collect certain information about the computer or
				devices (including mobile devices) you use to access the Services. For example, we may
				collect and analyze information such as (a) IP addresses, browser types, browser language,
				operating system, the state or country from which you accessed the Services; and (b)
				information related to the ways in which you interact with the Services, such as:
				referring and exit pages and URLs, platform type, the number of clicks, domain names,
				pages viewed, the date and time you used the Services, the frequency of your use of the
				Services, error logs, and other similar information.
			</li>
			<li>
				Location Information. We may collect different types of information about your location,
				including general information (e.g., IP address, zip code) and may use that information to
				customize the Services with location-based information and features. For example, if your
				IP address indicates an origin in Southampton, Bermuda, the Services may be customized
				with Southampton-specific information.
			</li>
			<li>
				Cookies and Other Electronic Technologies. We may use the tools outlined below in order to
				better understand users. As we adopt additional technologies, we may also gather
				additional information through other methods. Cookies: “Cookies” are small computer files
				transferred to your computing device that contain information such as user ID and local
				copies of submitted information. We use Cookies to help us improve or tailor the Services
				by storing your authentication status so you do not have to re-enter your credentials each
				time you use the Services, customizing your experience with the Services, and for
				analytics. For more information on cookies, visit{' '}
				<a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
					http://www.allaboutcookies.org
				</a>
				.
			</li>
		</ul>

		<h4>4. Aggregate or De-identified Data</h4>
		<p>
			We may aggregate and/or de-identify information collected by the Services or via other means
			so that the information is not intended to identify you. Our use and disclosure of
			aggregated and/or de-identified information is not subject to any restrictions under this
			Online Privacy Policy, and we may disclose it to others without limitation for any purpose,
			in accordance with applicable laws and regulations.
		</p>

		<h3>5. Use of Information</h3>
		<p>We use the information that we collect for the following purposes:</p>
		<ul>
			<li>For the purposes for which you provided the information;</li>
			<li>For public health activities and research purposes;</li>
			<li>To contact you when necessary or requested;</li>
			<li>
				To provide, maintain, administer, improve, or expand the Services, perform business
				analyses, or for other internal purposes to support, improve or enhance our business, the
				Services, and other products and services we offer;
			</li>
			<li>To customize and tailor your experience of the Services;</li>
			<li>To track and analyze trends and usage in connection with our Services;</li>
			<li>To develop and improve algorithms and software;</li>
			<li>
				To use statistical information that we collect in any way permitted by law, including from
				third parties, in connection with the study of public health concerns (e.g., COVID-19) and
				related public health activities;
			</li>
			<li>
				To prevent, detect, and investigate security breaches, fraud, and other potentially
				illegal or prohibited activities;
			</li>
			<li>To enforce the legal terms that govern your use of the Services;</li>
			<li>To protect our rights or property;</li>
			<li>To administer and troubleshoot the Services; and</li>
			<li>For any other purpose disclosed to you in connection with our Services.</li>
		</ul>
		<p>
			We may use third-party service providers to process and store personal information in the
			United States and other countries.
		</p>

		<h3>6. Sharing of Information</h3>
		<p>
			The information we collect during your assessment will not be correlated with contact
			information if you provide it. We may share personal information (e.g. your contact
			information) as follows:
		</p>
		<ul>
			<li>
				With third parties to provide, maintain, and improve our Services, including service
				providers who access information about you to perform health system related services on
				our behalf;
			</li>
			<li>
				With our affiliates and partners so that they may use such information for the purposes
				described in this Online Privacy Policy;
			</li>
			<li>
				If we believe that disclosure is reasonably necessary to comply with any applicable law,
				regulation, legal process or governmental request; to enforce applicable user agreements
				or policies; to protect the security or integrity of our Services; and to protect us, our
				users or the public from harm or illegal activities; and
			</li>
			<li>With your consent.</li>
		</ul>
		<p>
			We may also share aggregated, non-personally identifiable information with third parties,
			such as, but not limited to, Epidemiology and Surveillance Units and contact tracers.
		</p>

		<h3>7. Opt-In Policy</h3>
		<p>
			If you provide us with your contact information you are opting in to receiving
			communications from us in connection with re-checking your symptoms. You can contact us in
			accordance with the “Contact Us” section below to opt out from receiving such
			communications.
		</p>
		<p>
			If you decide to contact us to change your contact preferences to opt out of receiving
			communications from us, please specify clearly which of the following choices you are opting
			out of receiving communications from us.
		</p>
		<p>
			We will endeavor to implement your requested change as soon as reasonably practicable after
			receiving your request. Please be aware that your requested change will not be effective
			until we implement such change. Please note that if you choose not to allow our sharing of
			your personally identifiable information, we are not responsible for removing your
			personally identifiable information from the databases of public health third parties with
			which we have already shared your contact information as of the date that we implement your
			request.
		</p>

		<h3>8. Social Media and Third-Party Platforms</h3>
		<p>
			We discourage you from sharing person medical information on social media. The Health
			Council is not responsible for any act or omission of any Social Media Site, nor are we
			responsible for the consequences of your choosing to share your information on Social Media
			Sites.
		</p>

		<h3>9. Security</h3>
		<p>
			We take reasonable measures, including administrative, technical, and physical safeguards,
			to help protect personal information from loss, theft, misuse, unauthorized access,
			disclosure, alteration, and destruction. Unfortunately, no data transmission over the
			Internet can be guaranteed to be 100% secure. As a result, while we strive to protect your
			personal information, The Health Council cannot ensure or warrant the security of any
			information you transmit to us or from our online products or services, and you do so at
			your own risk.
		</p>

		<h3>10. Your Privacy Choices</h3>
		<ol>
			<li>
				<b>How You Can Access and Update Your Information</b>
			</li>
			<p>
				You may update or correct information about yourself at any time or by emailing us at{' '}
				<a href="mailto:healthcouncil@bhec.bm">healthcouncil@bhec.bm</a>.
			</p>
			<li>
				<b>Cookies</b>
			</li>
			<p>
				Most web browsers are set to accept cookies by default. If you prefer, you can usually
				choose to set your browser to remove or reject cookies; however, our Services may not
				function properly if you do so.
			</p>
			<li>
				<b>Options for Opting out of Cookies</b>
			</li>
			<p>
				If you are interested in more information about how you can generally control cookies from
				being put on your computer, visit{' '}
				<a href="http://www.allaboutcookies.org" target="_blank" rel="noopener noreferrer">
					http://www.allaboutcookies.org
				</a>
				.
			</p>
			<li>
				<b>How the Health Council Responds to Browser “Do Not Track” Signals</b>
			</li>
			<p>
				We do not recognize or respond to browser-initiated Do Not Track signals, as the Internet
				industry is currently still working on Do Not Track standards, implementations and
				solutions. For more information about DNT signals, visit{' '}
				<a href="http://allaboutdnt.com" target="_blank" rel="noopener noreferrer">
					http://allaboutdnt.com
				</a>
				.
			</p>
			<li>
				<b>Links to Other Websites</b>
			</li>
			<p>
				Our Services may contain links to other websites and those websites may not follow the
				same privacy practices as the Bermuda Health Council. We are not responsible for the
				privacy practices of third-party websites. We encourage you to read the privacy policies
				of such third parties to learn more about their privacy practices.
			</p>
			<li>
				<b>Your Bermuda Privacy Rights</b>
			</li>
			<p>
				While we do not and will never provide your personal information to marketers, we want you
				to know your rights. Bermuda law permits at the request of an individual for access to his
				personal information, and having regard to that which is reasonable, the Health Council
				shall provide the individual with access to personal information about the individual in
				the custody or under the control of the Health Council; the purposes for which the
				personal information has been and is being used by the Health Council; and the names of
				the persons or types of persons to whom and circumstances in which the personal
				information has been and is being disclosed. If you are a Bermuda resident and would like
				to request this information, please submit your request in an email to{' '}
				<a href="mailto:healthcouncil@bhec.bm">healthcouncil@bhec.bm</a>.
			</p>
			<li>
				<b>No Rights of Third Parties</b>
			</li>
			<p>This Online Privacy Policy does not create rights enforceable by third parties.</p>
			<li>
				<b>How to Contact Us</b>
			</li>
			<p>
				Please contact us with any questions or concerns regarding this Online Privacy Policy at:
			</p>
		</ol>
		<p>
			<b>Company: </b>Bermuda Health Council
		</p>
		<p>
			<b>Phone: </b>
			<a href="tel:+14412926420">441-292-6420</a>
		</p>
		<p>
			<b>Email: </b>
			<a href="mailto:healthcouncil@bhec.bm">healthcouncil@bhec.bm</a>
		</p>
	</div>)
}